const stampit = require('stampit');
const template = _.template(require('../../templates/chapter-fragments-editor/options.html'));


module.exports = stampit().methods({

    /**
     * Add an options toolbar to a fragment.
     *
     * @param  {Backbone.Model} model - Fragment model.
     */
    addOptions: function (model) {

        const $el = model.view.$el;

        if ($el.find('.'+ this.CLASS_OPTIONS).length) {
            return;
        }

        const $options = $(template());
        $el.find('.'+ this.CLASS_EDITOR).before($options);

        model.view.$options = $options;
        
        $options
            .find('.'+ this.CLASS_OPTIONS_TRIGGER)
            .on('click', e => {
                this.toggleOptions(model);
            });

        $options.
            find('.'+ this.CLASS_OPTIONS_OPTION).
            on('click', e => {
                this.hideOptions(model);

                const $target = $(e.target);
                const action = $target.data('action');

                this.replace(model, {
                    type: action
                });
            });

    },

    /**
     * Remove an options toolbar from a fragment.
     *
     * @param  {Backbone.Model} model - Fragment model.
     */
    removeOptions: function (model) {

        this.hideOptions(model);

        const $el = model.view.$el;
        $el.find('.'+ this.CLASS_OPTIONS).remove();
    },

    /**
     * Show/hide an options toolbar of a fragment.
     *
     * @param  {Backbone.Model} model - Fragment model.
     */
    toggleOptions: function (model) {

        const $options = model.view.$options;

        if (!$options) return;

        if ($options.hasClass(this.CLASS_OPTIONS_SHOWN)) {
            this.hideOptions(model);
        } else {
            this.showOptions(model);
        }
    },

    /**
     * Show options toolbar of a fragment.
     *
     * @param  {Backbone.Model} model - Fragment model.
     */
    showOptions: function (model) {

        const $options = model.view.$options;

        if (!$options) return;

        $options.addClass(this.CLASS_OPTIONS_SHOWN);
        $options.siblings('.'+ this.CLASS_EDITOR).addClass(this.CLASS_EDITOR_NOPLACEHOLDER);
    },

    /**
     * Hide options toolbar of a fragment.
     *
     * @param  {Backbone.Model} model - Fragment model.
     */
    hideOptions: function (model) {

        const $options = model.view.$options;

        if (!$options) return;

        $options.removeClass(this.CLASS_OPTIONS_SHOWN);
        $options.siblings('.'+ this.CLASS_EDITOR).removeClass(this.CLASS_EDITOR_NOPLACEHOLDER);
    }
});
