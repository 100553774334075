const utils = require('sharedComponents/js/utils');

module.exports = function () {

  $('.user1').addClass('user1_visible');

  // const userName = window.localStorage.getItem('user-name');
  const userName = utils.cookie.get('user-name')
  $('#user1').html(userName);

  const $menu = $('.user1__menu');
  const menu = new Foundation.DropdownMenu($menu, {
      clickOpen: true,
      disableHover: true
  });
  $menu.find('ul a').on('click', e => menu._hide());


  $('.user1__menu__courses').on('click', e => {
    e.preventDefault();
    console.log('user1__menu__courses click', config.API.WEBSITE);
    window.location.href = config.API.WEBSITE + '/dashboard';
    
    // app.logout();
  });

  $('.user1__menu__logout').on('click', e => {
    app.logout();
  });
};
