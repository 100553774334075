const stampit = require('stampit');
const utils = require('../../utils');
const template = _.template(require('../../../templates/chapter-fragments-editor/question.html'));
const ui = require('sharedComponents/js/ui');

module.exports = stampit().methods({

    /**
     * Create new fragment text with the model and view container created.
     *
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentQuestion: function (model, conf = {}){

        const _this = this;
        const $el = model.view.$el;

        // Set fragment inner template.
        const content = this.getFragmentContent(model);
        $el.find('.'+ this.CLASS_FRAGMENT_CONTENT).html(template({ content }));

        //
        // REMOVE
        //
        const constants = config.CMS.FRAGMENTS.DELETE;

        $el.find('.'+ this.CLASS_FRAGMENT_OPT_REMOVE).on('click', e => {
            $el.hide(0);

            ui.$createModal('confirm', {
            title: constants.TITLE,
            description: constants.DESCRIPTION,
            buttonAccept: constants.ACCEPT,
            buttonCancel: constants.CANCEL,
            onAccept: e => {
                this.remove(model);
            },
            onCancel: e => {
                $el.show(0);
            }
            });
        });

        /*
        * Reorder
        */
        $el.find('.button-order_up, .button-order_down').on('click', e => {
          let position;

          if ($(e.target)[0].dataset.direction == "up") {
            position = this.swapFragment(model, 'up');
          } else {
            position = this.swapFragment(model, 'down');
          }
          
          model.set({ position })
          setTimeout(() => { location.reload(); }, 750)
        });

        // Define the editor configuration.
        // console.log('this.CLASS_EDITOR', this.CLASS_EDITOR);
        // const nodeEditor = $el.find('.'+ this.CLASS_EDITOR)[0];
        // const editorSettings = {
        //     quoter: function (e) {
        //         const editor = this.base;
        //         const content = _this.getEditorContent(editor, true);
        //         _this.replace(model, {
        //             type: 'quote',
        //             content: content
        //         });
        //     }
        // };

        // const fragmentsTypeText = this.fragments.filter(f => f.get('type') === 'text');
        // if (conf.placeholder || fragmentsTypeText.length === 1) {
        //     editorSettings.placeholder = {
        //         text: 'Type your text...'
        //     };
        // }

        // Create the editor.
        // const editor = this.mediumEditor(nodeEditor, editorSettings);
        // model.view.editor = editor;
        // model.view.nodeEditor = nodeEditor;

        //
        // TITLE
        //
        const $title = $el.find('input[name="title"]');

        if(model.attributes.title) $title.val(model.attributes.title);

        $title.on('change', e => {
          this.cleanInput($title, false);
          const title = $title.val();

          model.set({ title });
        })

        //
        // QUESTIONS
        //
        const $answers = $el.find('.answers');
        const $answer1 = $el.find('.answer-1 input');
        const $answer2 = $el.find('.answer-2 input');
        const $answer3 = $el.find('.answer-3 input');
        const $answer4 = $el.find('.answer-4 input');

        let options = [
          {
            text: '',
            isCorrect: true
          },
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          },
          {
            text: '',
            isCorrect: false
          }
        ];

        if(model.attributes.options) {
          options = model.attributes.options;
          $answer1.val(model.attributes.options[0].text);
          $answer2.val(model.attributes.options[1].text);
          $answer3.val(model.attributes.options[2].text);
          $answer4.val(model.attributes.options[3].text);
        }

        $answers.on('change', e => {
          const currentElem = e.target;
          const id = currentElem.id;
          const answer1 = $answer1.val();
          const answer2 = $answer2.val();
          const answer3 = $answer3.val();
          const answer4 = $answer4.val();

          switch(id) {
            case 'answer1':
              let opt1 = [
                {
                  text: answer1,
                  isCorrect: true
                },
                {
                  text: answer2,
                  isCorrect: false
                },
                {
                  text: answer3,
                  isCorrect: false
                },
                {
                  text: answer4,
                  isCorrect: false
                }
              ]

              model.set({ options: opt1 });
              break;
            case 'answer2':
              let opt2 = [
                {
                  text: answer1,
                  isCorrect: true
                },
                {
                  text: answer2,
                  isCorrect: false
                },
                {
                  text: answer3,
                  isCorrect: false
                },
                {
                  text: answer4,
                  isCorrect: false
                }
              ]

              model.set({ options: opt2 });
              break;
            case 'answer3':
              let opt3 = [
                {
                  text: answer1,
                  isCorrect: true
                },
                {
                  text: answer2,
                  isCorrect: false
                },
                {
                  text: answer3,
                  isCorrect: false
                },
                {
                  text: answer4,
                  isCorrect: false
                }
              ]

              model.set({ options: opt3 });
              break;
            case 'answer4':
              let opt4 = [
                {
                  text: answer1,
                  isCorrect: true
                },
                {
                  text: answer2,
                  isCorrect: false
                },
                {
                  text: answer3,
                  isCorrect: false
                },
                {
                  text: answer4,
                  isCorrect: false
                }
              ]

              model.set({ options: opt4 });
              break;
          }
        });

        //
        // METHODS
        //
        const onFocus = e => {
            this.fragments.forEach(fragment => this.removeOptions(fragment));
            onChange(e);
        };

        const onUpdate = e => {
          console.log('onUpdate')
            // const content = this.getEditorContent(editor);
            // model.set({ content });
        };

        const onChange = e => {
            // const content = this.getEditorContent(editor, true);
            // if (content.length) {
            //     this.removeOptions(model);
            // } else {
            //     this.addOptions(model);
            // }
        };

        //
        // EVENTS
        //
        // this.mediumEditorEvents(model);

        // editor.subscribe('focus', onFocus);
        // editor.subscribe('editableInput', onChange);
        // editor.subscribe('editableInput', onUpdate);
        // editor.subscribe('editableBlur', onUpdate);

        //
        // TRIGGER EVENTS
        //
        onChange();

        if (conf.focus) {
            this.fragmentQuestionTrigger('focus', model, {
                end: conf.focusEnd
            });
        }
    },

    /**
     * Methods to execute in a fragment text type.
     *
     * @param  {String} method
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     * @param  {Boolean} [conf.start]
     * @param  {Boolean} [conf.end]
     */
    fragmentQuestionTrigger: function (method, model, conf = {}) {
        const $el = model.view.$el;
        const $title = $el.find('input[name="title"]');

        // Focus the main fragment content.
        const focus = () => {
            $title.trigger('focus');

            utils.win.scrollTo($el);
        };

        switch (method) {
            case 'focus': focus(); break;
            default: this.log(`Fragment Text trigger not found "${method}".`);
        }
    }
});
