/**
 * Creates a menu dropdown specially for a nav menu.
 */

const utils = require('./utils');
const template = _.template(require('../templates/vmenu/main.html'));


/**
 * Plugin internal state.
 *
 * @type {Object}
 */
const state = {
    views: []
};


/**
 * Methods.
 *
 * @type {Object}
 */
var methods = {

    /**
     * Locate the view according to the window dimentions.
     */
    locate () {

        var conf = this.data('vmenu');
        if (!conf) return this;

        var $menuTrigger = this.find('.vmenu__trigger');
        var $menuList = this.find('.vmenu__list');

        var h = utils.win.getDims().height;
        var top = $menuTrigger.position().top + $menuTrigger.outerHeight();

        $menuList.
            css({
                maxHeight: h - 120,
                top: top + 10
            });
    },

    toggle (e) {

        const conf = this.data('vmenu');
        if (!conf) return this;

        if (this.hasClass('vmenu_shown')) {
            methods.hide.call(this);
        } else {
            setTimeout(() => {
                methods.show.call(this);
            }, 10);
        }

        return this;
    },

    /**
     * Show the current view.
     */
    show () {

        var conf = this.data('vmenu');
        if (!conf) return this;

        const $menuTrigger = this.find('.vmenu__trigger');

        clearTimeout(this.data('vmenu-animstart'));

        methods.hideAll();

        methods.locate.call(this);

        this.
            addClass('vmenu_shown').
            find('.vmenu__list');

        const animstart = setTimeout(() => {
            if (conf.onShow) conf.onShow();
            this.data('vmenu-animstart', false);
        }, conf.animTime);

        this.data('vmenu-animstart', animstart);

        $menuTrigger.removeClass('icon-menu icon-chevron-right').addClass('icon-expand-more');

        if (utils.win.getDims().width <= conf.breakpointMobile) {
            utils.win.scrollTo({
                $el: $menuTrigger,
                time: conf.animTime*2,
                type: 'top',
                topOffset: -10
            });
        }

        return this;
    },

    /**
     * Hide the current view.
     */
    hide () {

        var conf = this.data('vmenu');
        if (!conf) return this;

        const $menuTrigger = this.find('.vmenu__trigger');

        clearTimeout(this.data('vmenu-animstart'));

        this.removeClass('vmenu_shown');

        if (conf.onHide) conf.onHide();

        $menuTrigger.removeClass('icon-expand-more').addClass('icon-chevron-right');

        return this;
    },

    /**
     * Hide all shown views.
     */
    hideAll () {
        state.views.forEach(v => {
            if (v.hasClass('vmenu_shown')) {
                methods.hide.call(v);
            }
        });
    },

    /**
     * Relocate all visible views.
     */
    locateAll () {
        state.views.forEach(v => {
            if (v.hasClass('vmenu_shown')) {
                methods.locate.call(v);
            }
        });
    },

    /**
     * Remove the plugin view form the list.
     */
    remove () {
        state.views = _(state.views).reject(view => {
            $(this).remove();
            return $(view)[0] === $(this)[0];
        });
    }
};


/**
 * Plugin API.
 *
 * @param  {Object} [conf]
 * @param  {String} [conf.title]
 * @param  {*} [conf.id]
 * @param  {Array} [conf.list]
 * @param  {Number} [conf.animTime]
 * @param  {Number} [conf.breakpointMobile]
 * @param  {Function} [conf.onHide]
 * @param  {Function} [conf.onShow]
 *
 * @return {jQuery}
 */
jQuery.fn.vmenu = function (conf) {

    if (typeof conf === 'string') {
        if (methods[conf]) {
            var args = Array.prototype.slice.call(arguments, 1);
            return methods[conf].apply(this, args);
        } else {
            throw new Error(`vmenu does not recognize method "${conf}".`);
        }
    }

    if (this.data('vmenu')) {
        return this;
    }

    conf = _({}).extend({
        title: null,
        id: null,
        list: [],
        breakpointMobile: 767,
        animTime: 125,
        onHide: null,
        onShow: null
    }, conf);

    conf.list = (conf.list || []).map(item => {
        item.link = item.link ? item.link : 'javascript:void 0;';
        return item;
    });

    this.data('vmenu', conf);
    state.views.push(this);

    var $menuTrigger = this.find('.vmenu__trigger');
    var $menuList = this.find('.vmenu__list');

    //
    // DOM
    //

    if (conf.list.length >= 6) {
        this.addClass('vmenu_multicolumn');
    }

    $menuList.html(template(conf));

    //
    // EVENTS
    //
    $menuTrigger.add($menuList).on('click', e => {

        if (this.hasClass('vmenu_shown')) {
            methods.hide.call(this);
        } else {
            methods.show.call(this);
        }
    });

    $('body').on('click', e => {
        const $t = $(e.target);

        if (!$t.hasClass('vmenu') && !$t.parents('.vmenu').length) {
            methods.hide.call(this);
        }
    });

    return this;
};
