// Polyfills
require('sharedComponents/js/browserStoragePolyfill');

// Plugins.
require('sharedComponents/js/inputpasswd');
require('sharedComponents/js/input-validator');
require('sharedComponents/js/vmenu');
require('sharedComponents/js/select1');
require('sharedComponents/js/field-counter');
require('sharedComponents/js/vfileuploader/vfileuploader');
require('sharedComponents/js/chapter-fragments-editor/chapter-fragments-editor');
//translation plugin
require('sharedComponents/js/translate');

const config = require('baseApp/config');
const utils = require('sharedComponents/js/utils');
const ui = require('sharedComponents/js/ui');
const userMenu = require('./usermenu');
const notifications = require('./notifications');
const acl = require('./acl');




//
// GLOBAL APP
//
window.app = _(window.app || {}).extend({
  ui,
  config,
  utils,
  VERSION: config.VERSION,
  apiAjax: new utils.Ajax(config.API.SERVER),
  lang: 'en'
});

//
// API
//
app.apiAjax.
  on('error', function (conf, results) {

    // If any of the requests arrives with status 401 (Unauthorized), then redirect
    // the user to the login site.
    if (results[0].status === 401) {
      if (conf.endpoint !== '/login') {
        window.location.href = '/login';
      }
    }

    // User is forbidden to fetch this resource.
    if (results[0].status === 403) {
      ui.$createModal('basic', {
        title: config.CMS.APP.MESSAGES.FORBIDDEN.TITLE,
        description: config.CMS.APP.MESSAGES.FORBIDDEN.DESCRIPTION,
        iconClose: true,
        iconCloseLink: '/',
        button: true,
        buttonText: config.CMS.APP.MESSAGES.FORBIDDEN.BUTTON,
        buttonLink: '/'
      });
    }
  });

//make a first ajax call to validate the user against the api
//if the user is not logged in, the app will redirect the user to the login
//page automatically.
$(document).ready(function ($) {

  if (!app.IS_OTHER_PAGE && window.location.pathname.indexOf('/login') !== 0) {
    app.apiAjax.request('/isAuthenticated')
    .then(function (r) {

      if (r.data === "not_logged_in") {
        app.redirectToLogin();
      } else {
        $('.app').addClass('app_logged');

        userMenu();
        notifications();
        acl();
      }
    });
  }
});

// Redirect to login.
window.app.redirectToLogin = function () {
  window.location.href = '/login';
};

// Easily logout from console
window.app.logout = function(){

    app.apiAjax.request('logout', {
        type: 'POST',
        success: function(r) {

            // Remove token
            console.log('logout')
            utils.cookie.set('token_auth', '')
            // window.localStorage.setItem('token', '');
            app.redirectToLogin();
        }
    });

};


//
// GOOGLE ANALYTICS
//
const IS_DEBUG = config.DEBUG;
const GA_CODE = config.CMS.GA.CODE;

if (!IS_DEBUG && GA_CODE) {

  (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
  (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
  m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','//www.google-analytics.com/analytics.js','ga');

  ga('create', GA_CODE, 'auto');
  ga('send', 'pageview');
}


//
// STATIC
//
$(document).ready(function ($) {

  //
  // VALUES
  //
  $('#app-static-year').text((new Date()).getFullYear());

  //
  // LINKS
  //
  $('a.link-social-facebook').attr('href',    config.API.SOCIAL_FACEBOOK);
  $('a.link-social-twitter').attr('href',     config.API.SOCIAL_TWITTER);
  $('a.link-social-instagram').attr('href',   config.API.SOCIAL_INSTAGRAM);
  $('a.link-social-tumblr').attr('href',      config.API.SOCIAL_TUMBLR);

  $('a.link-website').attr('href',            config.API.WEBSITE);
  $('a.link-website-info').attr('href',       config.API.WEBSITE_INFO);
  $('a.link-website-glossary').attr('href',   config.API.WEBSITE_GLOSSARY);
  $('a.link-website-faq').attr('href',        config.API.WEBSITE_FAQ);
  $('a.link-website-tips').attr('href',       config.API.WEBSITE_TIPS);

  $('a.link-website-contact').attr('href',    config.API.WEBSITE_CONTACT);
  $('a.link-website-help').attr('href',       config.API.WEBSITE_CONTACT);

  $('a.link-website-privacy').attr('href',    config.API.WEBSITE_PRIVACY);
  $('a.link-website-terms').attr('href',      config.API.WEBSITE_TERMS);
  $('a.link-website-terms-ads').attr('href',  config.API.WEBSITE_TERMS_ADS);

});
