const stampit = require('stampit');
const autosize = require('autosize');
const utils = require('../../utils');
const template = _.template(require('../../../templates/chapter-fragments-editor/text.html'));
const ui = require('sharedComponents/js/ui');

module.exports = stampit().methods({

    /**
     * Create new fragment text with the model and view container created.
     *
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentText: function (model, conf = {}){

        const _this = this;
        const $el = model.view.$el;

        // Set fragment inner template.
        const c = this.getFragmentContent(model);
        $el.find('.'+ this.CLASS_FRAGMENT_CONTENT).html(template({ content: c }));

        // // Define the editor configuration.
        // const nodeEditor = $el.find('.'+ this.CLASS_EDITOR)[0];
        // const editorSettings = {
        //     quoter: function (e) {
        //         const editor = this.base;
        //         const content = _this.getEditorContent(editor, true);
        //         _this.replace(model, {
        //             type: 'quote',
        //             content: content
        //         });
        //     }
        // };

        // const fragmentsTypeText = this.fragments.filter(f => f.get('type') === 'text');
        // if (conf.placeholder || fragmentsTypeText.length === 1) {
        //     editorSettings.placeholder = {
        //         text: 'Type your text...'
        //     };
        // }

        //
        // REMOVE
        //
        const constants = config.CMS.FRAGMENTS.DELETE;

        $el.find('.'+ this.CLASS_FRAGMENT_OPT_REMOVE).on('click', e => {
            $el.hide(0);

            ui.$createModal('confirm', {
            title: constants.TITLE,
            description: constants.DESCRIPTION,
            buttonAccept: constants.ACCEPT,
            buttonCancel: constants.CANCEL,
            onAccept: e => {
                this.remove(model);
            },
            onCancel: e => {
                $el.show(0);
            }
            });
        });

        /*
        * Reorder
        */
        $el.find('.button-order_up, .button-order_down').on('click', e => {
            let position;

            if ($(e.target)[0].dataset.direction == "up") {
            position = this.swapFragment(model, 'up');
            } else {
            position = this.swapFragment(model, 'down');
            }
            
            model.set({ position })
            setTimeout(() => { location.reload(); }, 750)
        });

        // Create the editor.
        // const editor = this.mediumEditor(nodeEditor, editorSettings);
        // model.view.editor = editor;
        // model.view.nodeEditor = nodeEditor;

        //
        // TITLE
        //
        const $title = $el.find('input[name="title"]');

        if(model.attributes.title) {
            $title.val(model.attributes.title);
        }

        $title
            .on('change', e => {
                this.cleanInput($title, false);
                const title = $title.val();
                model.set({ title });

                if(title.length) {
                    this.removeOptions(model);
                } else {
                    this.addOptions(model);
                }
            })


        //
        // CONTENT
        //
        const content = this.getFragmentContent(model, true);
        const $textarea = $el.find('textarea');

        autosize($textarea);
        $textarea.val(content);
        setTimeout(() => autosize.update($textarea), 0);

        $textarea
            .on('change', e => {
                this.cleanInput($textarea, true);

                const content = $textarea.val();
                model.set({ content });
            })
            .on('keypress', e => {
                // const key = e.which;
                // switch (key) {
                //     case this.KEY_ENTER:
                //         const position = this.getFragmentPosition(model);
                //         this.create(null, {
                //             position,
                //             focus: true
                //         });
                //         e.preventDefault();
                //         return false;
                // }
            });

        // //
        // // METHODS
        // //
        // const onFocus = e => {
        //     this.fragments.forEach(fragment => this.removeOptions(fragment));
        //     onChange(e);
        // };

        // const onUpdate = e => {
        //     const content = this.getEditorContent(editor);
        //     model.set({ content });
        // };

        // const onChange = e => {
        //     const content = this.getEditorContent(editor, true);

        //     // if (content.length) {
        //     //     this.removeOptions(model);
        //     // } else {
        //     //     this.addOptions(model);
        //     // }
        // };

        // //
        // // EVENTS
        // //
        // this.mediumEditorEvents(model);

        // editor.subscribe('focus', onFocus);
        // editor.subscribe('editableInput', onChange);
        // editor.subscribe('editableInput', onUpdate);
        // editor.subscribe('editableBlur', onUpdate);

        //
        // TRIGGER EVENTS
        //
        // onChange();

        // if (conf.focus) {
        //     this.fragmentTextTrigger('focus', model, {
        //         end: conf.focusEnd
        //     });
        // }

        //
        // TRIGGER EVENTS
        //
        if (conf.focus) {
            this.fragmentTextTrigger('focus', model);
        }
    },

    /**
     * Methods to execute in a fragment text type.
     *
     * @param  {String} method
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     * @param  {Boolean} [conf.start]
     * @param  {Boolean} [conf.end]
     */
    fragmentTextTrigger: function (method, model, conf = {}) {
        const $el = model.view.$el;
        const editor = model.view.editor;
        const $title = $el.find('input[name="title"]');

        // Focus the main fragment content.
        const focus = () => {
            const $el = model.view.$el;
            const $textarea = $el.find('textarea');

            $textarea.trigger('focus');
            utils.win.scrollTo($el);
        };

        switch (method) {
            case 'focus': focus(); break;
            default: this.log(`Fragment Text trigger not found "${method}".`);
        }
    }
});
