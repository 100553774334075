/**
 * Plugin API. Let you know when an input has been changed with some properties
 * of the content, as length and words number.
 *
 * @param  {Object} conf - The configuration.
 * @param  {Function} conf.callback - A function to call every time the field changes.
 *
 * @return {jQuery}
 */
jQuery.fn.bFieldCounter = function (conf) {

    if (typeof conf === 'function') {
        conf = {
            callback: conf
        };
    }

    conf = _.extend({}, {
        callback: null
    }, conf);

    this.each(function () {

        const $el = $(this);

        $el.on('input keyup change', function (e) {

            const value = $el.val();
            const length = value.trim().length;
            const words = length === 0 ? 0 : value.trim().split(' ').length;

            if (conf.callback) {
                conf.callback({
                    length: length,
                    words: words
                });
            }
        });
    });

    return this;
};
