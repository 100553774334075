const stampit = require('stampit');


module.exports = stampit().refs({

    CLASS_CONTAINER: 'cfe-container',

    CLASS_FRAGMENT: 'cfe-fragment',
    CLASS_FRAGMENT_CONTENT: 'cfe-fragment__content',
    CLASS_FRAGMENT_ALIGNED_LEFT: 'cfe-fragment__left',
    CLASS_FRAGMENT_OPT_REMOVE: 'cfe-editor-opt-remove',
    CLASS_FRAGMENT_CUSTOM: 'cfe-editor-custom',
    CLASS_FRAGMENT_FLICK: 'cfe-fragment_flick',

    CLASS_EDITOR: 'cfe-editor',
    CLASS_EDITOR_NOPLACEHOLDER: 'cfe-editor_noplaceholder',
    CLASS_EDITOR_ALIGN_OPTION: 'cfe-editor-align-opt',

    CLASS_OPTIONS: 'cfe-options',
    CLASS_OPTIONS_OPTION: 'cfe-options__opt',
    CLASS_OPTIONS_TRIGGER: 'cfe-options__trigger',
    CLASS_OPTIONS_SHOWN: 'cfe-options__shown',

    CLASS_TEXT: 'chapter__fragment_text',
    CLASS_QUOTE: 'chapter__fragment_quote',
    CLASS_LIST: 'chapter__fragment_list',
    CLASS_IMAGE: 'chapter__fragment_image',
    CLASS_VIDEO: 'chapter__fragment_video',
    CLASS_QUESTION: 'chapter__fragment_question',
    CLASS_TRANSITION: 'chapter__fragment_transition',
    CLASS_TEXT_IMAGE: 'chapter__fragment_text_image',

    KEY_BACKSPACE: 8,
    KEY_TAB: 9,
    KEY_ENTER: 13,
    KEY_LEFT: 37,
    KEY_UP: 38,
    KEY_RIGHT: 39,
    KEY_DOWN: 40,
    KEY_DELETE: 46,

    // Debug mode.
    debug: false,  // Boolean

    // Records of events in the editor.
    records: [],  // Array

    // The chapter model where the fragments information is stored in.
    chapterModel: null,  // Backbone.Model

    // Data structure of the chapter content divided in fragments.
    fragments: null,  // Backbone.collection

    // The element to hold the fragments editor.
    $element: null,  // jQuery

    // Medium Editors container.
    $container: null  // jQuery
});
