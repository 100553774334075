const stampit = require('stampit');
const autosize = require('autosize');
const config = require('../../config');
const utils = require('../../utils');
const template = _.template(require('../../../templates/chapter-fragments-editor/image.html'));
const templateContainer = _.template(require('../../../templates/chapter-fragments-editor/custom.html'));
const ui = require('sharedComponents/js/ui');
const Flash = require('components/flash');

module.exports = stampit().methods({

    /**
     * Create new fragment image with the model and view container created.
     *
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentImage: function (model, conf = {}) {

        const $el = model.view.$el;

        const $container = $(templateContainer());
        const $inner = $(template());

        $container.find('.'+ this.CLASS_FRAGMENT_CUSTOM).html($inner);
        $el.find('.'+ this.CLASS_FRAGMENT_CONTENT).html($container);

        //
        // REMOVE
        //
        const constants = config.CMS.FRAGMENTS.DELETE;

        $el.find('.'+ this.CLASS_FRAGMENT_OPT_REMOVE).on('click', e => {
            $el.hide(0);

            ui.$createModal('confirm', {
            title: constants.TITLE,
            description: constants.DESCRIPTION,
            buttonAccept: constants.ACCEPT,
            buttonCancel: constants.CANCEL,
            onAccept: e => {
                this.remove(model);
            },
            onCancel: e => {
                $el.show(0);
            }
            });
        });

        /*
        * Reorder
        */
        $el.find('.button-order_up, .button-order_down').on('click', e => {
          let position;

          if ($(e.target)[0].dataset.direction == "up") {
            position = this.swapFragment(model, 'up');
          } else {
            position = this.swapFragment(model, 'down');
          }
          
          model.set({ position })
          setTimeout(() => { location.reload(); }, 750)
        });

        //this.swapFragment(model, direction);

        //
        // ALIGN
        //
        this.fragmentImageTrigger('align', model);

        $el.find('.'+ this.CLASS_EDITOR_ALIGN_OPTION).on('click', e => {
            const align = $(e.target).data('action');
            model.set({ align });
            this.fragmentImageTrigger('align', model);
        });

        //
        // IMAGE FILE
        //
        const token = utils.cookie.get('token_auth')
        // const token = window.localStorage.getItem('token');
        const chapterId = this.chapterModel.get('id');
        const img = $el[0].querySelector('img');
        const inputFile = $el[0].querySelector('input[type=file]');
        
        const maxSize = parseFloat($(inputFile).data('max-mb')) || 2;
        inputFile.addEventListener('change', handleFiles, false);


        const url = model.get('url');
        if((typeof url !== 'undefined') && (url !== null)) {
            img.src = config.API.WEBSITE + url;
        }

        function handleFiles() {
            const inputFile = $el[0].querySelector('input[type=file]');
            const file = inputFile.files[0];
            if(!file) {
                Flash.error("Select a file");
                return;
            }

            // call them as such; files[0].size will get you the file size of the 0th file
            const filesize = ((file.size / 1024) / 1024); // MB
            if (filesize >= maxSize) {
                console.warn('Your file is too heavy. Please select a file under '+maxSize+'mo');
                Flash.error('Your file is too heavy. Please select a file under '+maxSize+'mo')

                return;
            }

            const progress = $el[0].querySelector('#progress');
            const result = $el[0].querySelector('#result');

            fetch(`${config.API.SERVER}/chapters/${chapterId}/upload`, {
                method: "POST",
                body: JSON.stringify({ filename: file.name }),
                headers: {
                    'x-token-auth' : token
                }
            }).then(function(r) {
                return r.json()
            }).then(function(json) {
                let data = new FormData();
                Object.entries(json.data.upload.fields).forEach(([k,v]) => {
                    data.append(k, v);
                })
                data.append('file', file); // Must be last field
                let request = new XMLHttpRequest();
                request.open('POST', json.data.upload.url);
                request.upload.addEventListener('progress', function(e) {
                    let percent_completed = (e.loaded / e.total)*100;
                    progress.innerHTML = Math.round(percent_completed) + "%"

                    if(Math.round(percent_completed) == 100) { progress.style.opacity = 0 }
                    else { progress.style.opacity = 1 }
                });
                request.addEventListener('load', function(e) {
                    // Upload done, file can be loaded at path
                    const url = json.data.path;
                    result.src = config.API.WEBSITE + url;

                    model.set({ url });
                });
                request.send(data);
            }).catch(function (ex) {
                console.error(ex)
            });
        }

        //
        // DESCRIPTION
        //
        const content = this.getFragmentContent(model, true);
        const $textarea = $el.find('textarea');

        autosize($textarea);
        $textarea.val(content);
        setTimeout(() => autosize.update($textarea), 0);

        $textarea
            .on('change', e => {
                this.cleanInput($textarea, true);

                const content = $textarea.val();
                model.set({ content });
            })
            .on('keypress', e => {
                // const key = e.which;
                // switch (key) {
                //     case this.KEY_ENTER:
                //         const position = this.getFragmentPosition(model);
                //         this.create(null, {
                //             position,
                //             focus: true
                //         });
                //         e.preventDefault();
                //         return false;
                // }
            });

        //
        // TRIGGER EVENTS
        //
        if (conf.focus) {
            this.fragmentImageTrigger('focus', model);
        }
    },

    /**
     * Methods to execute in a fragment image type.
     *
     * @param  {String} method
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentImageTrigger: function (method, model, conf={}) {

        // Focus the main fragment content.
        const focus = () => {

            const $el = model.view.$el;
            const $textarea = $el.find('textarea');

            $textarea.trigger('focus');
            utils.win.scrollTo($el);
        };

        // Update the current fragment alignment.
        const align = () => {

            const $el = model.view.$el;
            const $textarea = $el.find('textarea');
            const $input = $el.find('input[type=file]');
            const align = model.get('align') ? model.get('align') : 'center';

            $el.find('.'+ this.CLASS_EDITOR_ALIGN_OPTION).removeClass('active');
            $el.find('.'+ this.CLASS_EDITOR_ALIGN_OPTION +`[data-action=${align}]`).addClass('active');

            if (align === 'left') {
                $el.addClass(this.CLASS_FRAGMENT_ALIGNED_LEFT);
            } else {
                $el.removeClass(this.CLASS_FRAGMENT_ALIGNED_LEFT);
            }

            setTimeout(() => autosize.update($textarea), 0);

            $input.vFileUploader('resize');

            this.validateMultipleCustomFragments(model);
        };

        // Animation to show the user this fragment is placed where it is.
        const flick = () => {
            const $el = model.view.$el;
            $el.removeClass(this.CLASS_FRAGMENT_FLICK);
            focus();
            setTimeout(() => {
                $el.addClass(this.CLASS_FRAGMENT_FLICK);
            }, config.ANIM.TIME/2 + 10);
        };

        switch (method) {
            case 'focus':   focus(); break;
            case 'align':   align(); break;
            case 'flick':   flick(); break;
            default: this.log(`Fragment Image trigger not found "${method}".`);
        }
    }
});
