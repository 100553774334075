const stampit = require('stampit');
const uuid = require('uuid');
const utils = require('../../utils');
const ChapterFragmentModel = require('../../models/ChapterFragment');
const ChapterFragmentView = require('../ChapterFragmentView');


module.exports = stampit().methods({

    /**
     * This method creates a new fragment with the configuration defined.
     *
     * @param  {Object} fragment - The fragment definition. The properties are
     * the same as the `ChapterFragment` model.
     * @param  {Object} [conf] - Configuration.
     * @param  {String|Number} [conf.position] - Define the position where the fragment
     * will be placed. It can be a number defining the index in the fragments collection,
     * or it can be an string, defining if it will be the first one or the last one.
     * @param  {String|Boolean} [conf.placeholder]
     * @param  {Boolean} [conf.focus]
     * @param  {Boolean} [conf.focusEnd]
     *
     * @return {Backbone.Model} - Returns the new created fragment model.
     */
    create: function (fragment, conf) {

        
        // Parse configuration.
        fragment = _({
            id: uuid.v4(),
            chapter: this.chapterModel.get('id'),
            type: 'text',
            content: ''
        })
        .extend(fragment);
        
        conf = _({
            position: 'last',
            placeholder: false,
            focus: false,
            focusEnd: false,
            initial: false
        })
        .extend(conf);

        // Validate identifier.
        if (this.fragments.get(fragment.id)) {
            throw new Error('The chapter fragment is already created.');
        }

        // Validate if there is an empty fragment text prev or next to where we are
        // creating this one. If the new fragment is empty too.
        if (!conf.initial && this.fragments.length && !fragment.content) {

            const referencePosition = conf.position === 'last' ? this.fragments.length-1 :
                conf.position === 'first' ? 0 : conf.position;
            const reference = this.fragments.at(referencePosition);
            const referenceNext = this.getNextFragment(reference);

            if (reference && reference.get('type') === fragment.type &&
            this.isFragmentEmpty(reference)) {
                this.perform('focus', reference);
                return;
            }

            if (referenceNext && referenceNext.get('type') === fragment.type &&
            this.isFragmentEmpty(referenceNext)) {
                this.perform('focus', referenceNext);
                return;
            }
        }

        // Create model.
        const model = new ChapterFragmentModel(fragment);
        model.cfe = this;

        // The view will take care of initializing the fragment according to its type.
        const view = new ChapterFragmentView({ model });
        model.view = view;

        // Add to collection and DOM.
        if (typeof conf.position === 'string') {
            if (conf.position === 'first') {
                this.fragments.unshift(model);
                this.$element.prepend(view.$el);
            } else {
                this.fragments.push(model);
                this.$element.append(view.$el);
            }
        } else {
            this.fragments.add(model, {
                at: conf.position + 1
            });

            if (!this.$element.find('.'+ this.CLASS_FRAGMENT).length) {
                this.$element.append(view.$el);
            } else {
                this.$element.
                    find('.'+ this.CLASS_FRAGMENT).
                    eq(conf.position).
                    after(view.$el);
            }
        }

        // Detect when an already created fragment content is changed (if removed,
        // it is fist changed).
        if (conf.initial) {
            model.onChangeInitial = utils.general.throttle(() => {
                this.chapterModel.trigger('fragmentChange', model);
            }, 10000);
            model.on('change:content', model.onChangeInitial);
        }

        // We have the new fragment model and view, but they are just holders. Now,
        // we delegate the rest of the process to the corresponding controller
        // according to its type.
        switch (fragment.type) {
            case 'image'      : this.fragmentImage(model, conf); break;
            case 'video'      : this.fragmentVideo(model, conf); break;
            case 'list'       : this.fragmentList(model, conf); break;
            case 'question'   : this.fragmentQuestion(model, conf); break;
            case 'transition' : this.fragmentTransition(model, conf); break;
            case 'text_image' : this.fragmentTextImage(model, conf); break;
            default:            this.fragmentText(model, conf);  break;
        }

        this.log(`Created fragment "${fragment.id}" type "${fragment.type}" at "${conf.position}".`);

        // If user creates a fragment not type text and it is the last one, then
        // create as a new last one, a fragment text.
        const currentPosition = this.getFragmentPosition(model);
        // if (!conf.initial && currentPosition === this.fragments.length-1 &&
        // model.get('type') !== 'text') {
        //     this.create(null, { position: 'last' });
        // }

        return model;
    }
});
