const stampit = require('stampit');


module.exports = stampit().methods({

    /**
     * Remove a fragment from the chapter content. This removes the model from
     * the fragments collection and from the DOM.
     *
     * @param  {Backbone.Model} model - Fragment model.
     * @param  {Object} [conf]
     */
    remove: function (model, conf) {

        conf = _({
            focus: true
        })
        .extend(conf);

        if (this.fragments.length === 1) {
            return;
        }

        if (conf.focus) {
            const position = this.getFragmentPosition(model);
            if (position === 0) {
                const next = this.getNextFragment(model);
                this.perform('focus', next, { start: true });
            } else {
                const prev = this.getPrevFragment(model);
                this.perform('focus', prev, { end: true });
            }
        }

        if (model.view.editor && typeof model.view.editor.destroy === 'function') {
            model.view.editor.destroy();

            if (model.view.nodeEditor) {
                $(model.view.nodeEditor).remove();
            }
        }

        model.view.remove();
        this.fragments.remove(model);

        this.log(`Fragment removed "${model.get('id')}".`);
    }
});
