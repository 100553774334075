const metrics = require('./metrics');

var parseString = function(str){
    var exp = /\[(\w{2}):([\s\S]*?)\/\]/g;
    var m;
    var lang = {};
    while ((m = exp.exec(str))) {
        lang[m[1]] = m[2];
    }
    //fallback
    if(lang.en === undefined){
        lang.en = str;
    }
    if(!lang.es){
        lang.es = lang.en || '';
    }
    const metric = window.localStorage.getItem('metric') || 'us';
    const result = {};
    Object.keys(lang).forEach( (l) => {
       result[l] = metric === 'us'
            ? metrics.convertIntToUs(lang[l])
            : metrics.convertUsToInt(lang[l]);
    });
    return result;
};

var uniqueID = function () {
  // Math.random should be unique because of its seeding algorithm.
  // Convert it to base 36 (numbers + letters), and grab the first 9 characters
  // after the decimal.
  return '_' + Date.now() + Math.random().toString(36).slice(2);
};

/**
 * Translate a string
 * @param  String str [description]
 * @return String     [description]
 */

window.getCookie = function(name) {
  const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) {
    return match[2];
  }
  return null;
};

$.locale  = function() {
    const current = window.getCookie('lang');
    if (!current) {
        return 'en';
    }
    return current;
};

$.cmsLocale  = function() {
    const current = window.getCookie('cms_lang');
    if (!current) {
        return 'en';
    }
    return current;
};

$.metric  = function() {
    const current = window.getCookie('metric');
    if (!current) {
        return 'us';
    }
    return current;
};

$._trans = function(str, lang){
    var s = parseString(str);
    // return s[lang || $.locale()];
    return s[lang || $.cmsLocale()];
};

$.fn.multiLangInput = function(opts) {
    this.each(function(a,el){
        var $this = $(el),
            copy  = $this.clone(),
            val   = $this.val(),
            lang  = parseString(val),
            id    = $this.attr('id');

        //set original conf
        $this.attr('data-lang', 'en')
            .val(lang.en)
            .attr('value', lang.en);

        //has id?
        if(!id){
           id = uniqueID();
           $this.attr('id', id);
        }

        //if exist
        // console.info('exist', $this.getNext());
        $this.getNext().remove();

        //set copy
        copy.removeAttr('name')
            .removeAttr('id')
            .removeAttr('value')
            .text('')
            .attr('value', lang.es)
            .attr('data-lang', 'es')
            .attr('data-lang-for', id)
            .addClass('hide')
            .val(lang.es);
        $this.after(copy);

        //event copy
        copy.on('change', function(e){
            console.log('cambió el clone y dispara al original');
            $this.trigger('change');
        });
    });
    this.trigger('change');

    $.addLangSelector();

    // $.setLang();
    $.setCMSLang();
};

$.fn.getNext = function(){
    var p   = this.parent(),
        sel = '[data-lang-for="' + this.attr('id') + '"]',
        a   = $(sel, p);
    // console.info(sel, a);
    return  a;
}

$.fn.getVal = function() {
    var $this = $(this);
    var $container = $this.parent();
    var $esVal = $container.find('[data-lang="es"]:first');
    var $enVal = $container.find('[data-lang="en"]:first');

    return '[en:{{en}}/][es:{{es}}/]'
        .replace('{{en}}', $enVal.val())
        .replace('{{es}}', $esVal.val());
};

$.fn.getValWithSpace = function() {
    var $this = $(this);
    var $container = $this.parent();
    var $esVal = $container.find('[data-lang="es"]:first');
    var $enVal = $container.find('[data-lang="en"]:first');

    return '[en:{{en}}/][es:{{es}}/]'
        .replace('{{en}}', $enVal.val().replace(/\n/g, "<br />"))
        .replace('{{es}}', $esVal.val().replace(/\n/g, "<br />"));
};

$.fn.translate = function(){
    var lang = $.cmsLocale();
    return this.each(function(a,el) {
        var t     = $(this);
        var text = t.html().replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>');
        var trans = parseString(text);
        var str   = trans[lang];
        t.attr('data-lang-es', trans.es)
           .attr('data-lang-en', trans.en);
        t.html(str);
    });
};

$.fn.multiLangFile = function(){
    this.each(function(a,el){
        var $t   = $(this),
            id   = $t.attr('id'),
            $p   = $('[for=' + id + '].vfileuploader__dropzone', $t.parent()).parent(),
            lang = $t.data('lang');
        $p.attr('data-lang', lang);
    });

    $.addLangSelector();

    // $.setLang();
    $.setCMSLang();
};

$.addLangSelector = function() {

    if($('#lang-selector').length > 0){
        return;
    }

    $('<div id="lang-selector">' +
        '<input type="radio" name="lang" value="es" id="lang-es-btn" />' +
        '<label for="lang-es-btn">Español</label>' +
        '<input type="radio" name="lang" value="en" id="lang-en-btn" />' +
        '<label for="lang-en-btn">English</label>' +
        '</div>')
        .appendTo($('body'));

    // Change language on checked
    $('body').on('change', '#lang-selector [name=lang]', function(){
        var lang = $(this).val();
        $.setCMSLang(lang);
        // $.setLang(lang);
    });

    setTimeout( () => {
        // DEFAULT VALUE KEEP IT CONNECTED TO CURRENT USER LANG
        $('#lang-selector [value="' + $.cmsLocale() +  '"]').prop('checked', true);
    }, 100);

};

$.setLang = function(id){
    // console.log('setLang', { id, locale: $.locale(), cmsLocale: $.cmsLocale() })
    if(id){
        window.localStorage.setItem('lang' , id);
        const metric = window.localStorage.getItem('metric') || 'us';
        document.cookie = `lang=${id}; path=/`;
        document.cookie = `metric=${metric}; path=/`;
        window.lang = id;
    }

    $('[data-lang]').addClass('hide');
    $('[data-lang=' + $.locale() + ']').removeClass('hide');

    //render
    $('[data-lang-en]').each(function(){
        var lang = $.locale();

        // The $(this).data('lang-'+lang);
        // does not work properly
        var text = $(this).attr('data-lang-'+lang);
        $(this).text(text);
    });

    //only emit if lang changes
    if(id){
        $(document).trigger('change:lang');
    }
};

$.setCMSLang = function(id){
    // console.log('setCMSLang', { id, locale: $.locale(), cmsLocale: $.cmsLocale() })

    if(id){
        window.localStorage.setItem('cms_lang' , id);
        document.cookie = `cms_lang=${id}; path=/`;
        // window.lang = id;
    }

    $('[data-lang]').addClass('hide');
    $('[data-lang=' + $.cmsLocale() + ']').removeClass('hide');

    //render
    $('[data-lang-en]').each(function(){
        var lang = $.cmsLocale();
        // console.log('[data-lang-en] need to translate', this)

        // The $(this).data('lang-'+lang);
        // does not work properly
        var text = $(this).attr('data-lang-'+lang);
        $(this).text(text);
    });

    //only emit if lang changes
    if(id){
        $(document).trigger('change:lang');
    }
};
