const ChapterFragmentModel = Backbone.Model.extend({

    defaults: {
        id: undefined,  // String
        chapter: undefined,  // String (UUID)
        type: undefined,  // String ('text' | 'quote' | 'image' | 'video')
        content: undefined,  // String
        url: undefined,  // String | Object ({provider:String, product:String} | undefined)
        align: undefined  // String ('left' | 'center' | undefined)
    }

});

module.exports = ChapterFragmentModel;
