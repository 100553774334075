const S = require('string');
const config = require('../config');
const ui = require('../ui');
const jqueryFileUploadValidate = require('./jquery-fileupload-validate');
const PxLoader = require('../pxloader/PxLoader');
const template = _.template(require('../../templates/vfileuploader/main.html'));

const utils = require('sharedComponents/js/utils');

const PREFIX = 'vFileUploader';


// TODO: Disable the uploader when it is uploading files.


jQuery.vFileUploader = {
    ERROR_SIZE_EXCEEDED: 'ERROR_SIZE_EXCEEDED',
    ERROR_FORMAT: 'ERROR_FORMAT'
};


$(document).on('drop dragover', function (e) {
    e.preventDefault();
});


const methods = {

    /**
     * Set a file as uploaded in this instance.
     *
     * @param  {Object} values
     * @param  {String} values.image
     * @param  {String} [values.name]
     */
    update: function (values) {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        methods.setUploaded.call(this, values);

        return this;
    },

    /**
     * Get the current file.
     *
     * @return {Object}
     */
    get: function () {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        return {
            url: conf._url,
            name: conf._name
        };
    },

    /**
     * Set the current instance as empty, ready to upload a new file.
     */
    setUpload: function () {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        methods.reset.call(this);

        const text = conf.type === 'image' ?
            config.SETTINGS.FILEUPLOADER.DROP_IMAGE :
            config.SETTINGS.FILEUPLOADER.DROP_FILE;

        conf._$msg.text(text);

        return this;
    },

    /**
     * Set the current instance as uploading a file.
     */
    setUploading: function () {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        methods.reset.call(this);
        methods.updateProgress.call(this, 0);

        conf._$container.addClass('vfileuploader_uploading');
        conf._$msg.text('Uploading');

        this.trigger(`${PREFIX}-uploading`);

        return this;
    },

    /**
     * Update the uploading progress information.
     *
     * @param  {Number} progress
     */
    updateProgress: function (progress = 0) {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        conf._$container.find('.vfileuploader__progressbar span').width(`${progress}%`);

        this.trigger(`${PREFIX}-progress`, progress);

        return this;
    },

    /**
     * Set the current instance as if an error ocurred.
     *
     * @param {Object} info - Information about the error.
     */
    setError: function (info) {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        methods.reset.call(this);
        methods.updateProgress.call(this, 100);

        conf._$container.addClass('vfileuploader_error');
        conf._$msg.text(config.SETTINGS.FILEUPLOADER.ERROR_SERVER);

        this.trigger(`${PREFIX}-error`, info);
        this.trigger(`${PREFIX}-complete`);

        return this;
    },

    /**
     * Set the current instance as a file has been uploaded with this.
     *
     * @param {Object} settings - The information about the file uploaded.
     */
    setUploaded: function (settings = {}) {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        const url = settings.url;
        const file = settings.file;
        const name = settings.name;

        conf._url = url;
        conf._file = file;
        conf._name = name;

        methods.reset.call(this);
        methods.updateProgress.call(this, 100);
        conf._$container.addClass('vfileuploader_uploaded');

        let text;

        // Normal file.
        if (conf.type !== 'image') {

            text = name ? name :
                file && file.name ? file.name :
                config.SETTINGS.FILEUPLOADER.UPLOAD_FILE;
        }

        // Image.
        else {
            text = config.SETTINGS.FILEUPLOADER.UPLOAD_IMAGE;

            // Load image.
            methods.loadImage.call(this);
        }

        // Text.
        conf._$msg.text(text);

        if (!settings.initial) {
            this.trigger(`${PREFIX}-change`, {
                url,
                name: file && file.name ? file.name : name ? name : null
            });
            this.trigger(`${PREFIX}-complete`);
        }

        return this;
    },

    /**
     * Reset the current instance styles and states.
     */
    reset: function () {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        conf._$container.
            removeClass([
                'vfileuploader_uploading',
                'vfileuploader_uploaded',
                'vfileuploader_error',
            ].join(' '));

        conf._$dropzone.removeAttr('style');

        return this;
    },

    /**
     * When an image has been uploaded, set it on the instance and resize it.
     */
    resize: function () {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        const $dropzone = conf._$dropzone;
        const width = conf._width;
        const height = conf._height;
        const dropzoneWidth = $dropzone.width();

        let dropzoneHeight;

        if (conf.imageResize) {

            if (width && height) {
                dropzoneHeight = Math.round(dropzoneWidth * height / width);
            } else {
                dropzoneHeight = Math.round(dropzoneWidth * 0.7);
            }

            $dropzone.height(dropzoneHeight);
        }

        return this;
    },

    /**
     * When an image has been uploaded, load the image in the instance.
     *
     * @param  {String} url - File image URL.
     */
    loadImage: function (url) {

        const conf = this.data(`${PREFIX}-config`);
        if (!conf) return;

        url = url || conf._url || conf.url;
        conf._url = url;

        const $input = $(this);
        const $dropzone = conf._$dropzone;
        const loader = new PxLoader();
        const img = loader.addImage(url);

        $input.vFileUploader('resize');

        $dropzone.css('background-image', `url(${url})`);

        loader.addCompletionListener(function () {

            const width = img.naturalWidth || img.width;
            const height = img.naturalHeight || img.height;

            conf._width = width;
            conf._height = height;

            $input.vFileUploader('resize');
        });

        loader.start();

        return this;
    }

};


jQuery.fn.vFileUploader = function (settings) {

    const $input = $(this).first();

    if (typeof settings === 'string') {
        if (methods[settings]) {
            const args = Array.prototype.slice.call(arguments, 1);
            return methods[settings].apply($input, args);
        } else {
            throw new Error(`vfileuploader method unrecognized "${settings}".`);
        }
    }

    if ($input.data(`${PREFIX}-config`)) return;

    //
    // CONFIGURATION
    //
    let id = $input.attr('id');
    if (!id) {
        id = 'vfileuploader-'+ (new Date()).getTime();
        $input.attr('id', id);
    }

    const conf = _.extend({

        _id: id,

        // The kind of file the uploader will treat.
        type: 'all',  // String: 'all' | 'image' | 'pdf'

        // This is the initial value
        url: null,  // String

        // Show the file name?
        showFileName: false,  // Boolean

        // If `showFileName` and `url` is true, the initial file name. If no file
        // name is provided with an initial `url`, the `url` is set as file name.
        fileName: '',  // String
        name: '',

        // When is `type: 'image'`, if the image is treated as background contain,
        // instead of cover and being resized.
        imageContain: false,

        // When is `type: 'image'`, resize the image previsualizator when image is
        // loaded. If this is set to false, the background will have to be resized
        // manually.
        imageResize: true,

        // If the file can be removed, insert an option to do so.
        canRemove: true,  // Boolean

        // Show the validations visually. Programmer has to attach an event
        // handler to detect errors if set to false.
        showValidations: false

    }, settings);

    conf.name = !conf.name && conf.fileName ? conf.fileName : conf.name;

    // const token = window.localStorage.getItem('token');
    const token = utils.cookie.get('token_auth')
    // File Uploader options.
    conf.uploader = _.extend({

        // Server request.
        url: S(config.API.SERVER).chompRight('/') +'/file',
        type: 'POST',
        beforeSend: function(req) {
            req.setRequestHeader("x-token-auth", token || '');
        },
        paramName: 'file',
        //formData: [
        //    { name: 'key', value: 'value' }
        //],

        // Server response.
        dataType: 'json',
        singleFileUploads: true,
        limitMultiFileUploads: 1,

        // Behaviour.
        autoUpload: true,

        // DOM.
        fileInput: $input,
        dropZone: null,  // Later re-configurated.
        replaceFileInput: false,

        // Validation.
        maxNumberOfFiles: 1,  // Only one file per instance.
        maxFileSize: 2000000,  // 2 MB
        acceptFileTypes: undefined // All file types.
    }, settings.uploader);

    // File type validation.
    switch (conf.type) {
        case 'image':
            conf.uploader.acceptFileTypes = /(\.|\/)(gif|jpe?g|png|svg|webp)$/i;
            conf.uploader._acceptFileTypesFormats = 'JPG, JPEG, PNG, GIF';
            break;
        case 'pdf':
            conf.uploader.acceptFileTypes = /(application\/pdf|application\/x\-pdf)/i;
            conf.uploader._acceptFileTypesFormats = 'PDF';
            break;
    }

    $input.data(`${PREFIX}-config`, conf);

    //
    // DOM
    //
    const templateConf = { id, type: conf.type };
    const $container = $(template(templateConf));
    const $dropzone = $container.find('.vfileuploader__dropzone');
    const $msg = $container.find('.vfileuploader__msg');

    $input.after($container);
    $input.addClass('hidden');

    conf.uploader.dropZone = $container;
    conf._$container = $container;
    conf._$dropzone = $dropzone;
    conf._$msg = $msg;

    if (conf.type === 'image') {
        $container.addClass('vfileuploader_isimage');
    }

    if (conf.imageContain) {
        $container.addClass('vfileuploader_isimagecontain');
    }

    //
    // EVENTS
    //
    $input.

        // On send file.
        on('fileuploadsend', function (e, data) {
            methods.setUploading.call($input);
        }).

        // On progress updated.
        on('fileuploadprogressall', function (e, data) {

            const progress = parseInt(data.loaded / data.total * 100, 10);
            methods.updateProgress.call($input, progress);
        }).

        // On success.
        on('fileuploaddone', function (e, data) {

            const result = data.result;
            const files = data.files;

            if (!result.success || !result.data || !result.data.length) {
                methods.setError.call($input);
            } else {
                methods.setUploaded.call($input, {
                    url: result.data[0].url,
                    file: files[0]
                });
            }
        }).

        // On error at user file selection.
        on('fileuploadprocessfail', function (e, info) {

            const err = info.files[0].error;

            if (err === jQuery.vFileUploader.ERROR_FORMAT) {

                const description = _.template(config.SETTINGS.FILEUPLOADER.ERROR_FORMAT.DESCRIPTION);
                ui.$createModal('basic', {
                    title: config.SETTINGS.FILEUPLOADER.ERROR_FORMAT.TITLE,
                    description: description({
                        formats: conf.uploader._acceptFileTypesFormats
                    }),
                    button: true,
                    buttonText: 'OK',
                    iconClose: true
                });
            }
            else if (err === jQuery.vFileUploader.ERROR_SIZE_EXCEEDED) {
                ui.$createModal('basic', {
                    title: config.SETTINGS.FILEUPLOADER.ERROR_SIZE_EXCEEDED.TITLE,
                    description: config.SETTINGS.FILEUPLOADER.ERROR_SIZE_EXCEEDED.DESCRIPTION,
                    button: true,
                    buttonText: 'OK',
                    iconClose: true
                });
            }
        }).

        // On error.
        on('fileuploadfail', function (e, data) {
            methods.setError.call($input);
        });

    $dropzone.
        on('dragenter dragover', e => {
            $container.addClass('vfileuploader_dragover');
        }).
        on('dragleave drop', e => {
            $container.removeClass('vfileuploader_dragover');
        });

    $container.find('.vfileuploader__remove').on('click', function (e) {
        e.preventDefault();

        methods.setUpload.call($input);
        methods.updateProgress.call($input, 0);
        $input.trigger(`${PREFIX}-change`, {
            url: null,
            name: null
        });

        return false;
    });


    //
    // CREATING AND SETTING
    //
    $input.fileupload(conf.uploader);

    if (conf.url) {
        methods.setUploaded.call(this, {
            url: conf.url,
            name: conf.name,
            initial: true
        });
    } else {
        methods.setUpload.call(this);
    }

    return this;
};
