var sha1        = require('sha1');
const utils = require('sharedComponents/js/utils');

module.exports = function () {

    // If we are treating with a 403, 404 or 500 page, don't procced any further
    // with the validation.
    if (app.IS_OTHER_PAGE) {
        return;
    }

    $('body').addClass('hide');

    // Get role signed
    // var signed  = window.localStorage.getItem('role');
    var signed  = utils.cookie.get('role');

    // Get Role
    var role    = parseInt(signed.split('-')[0]);

    // check role with the signature
    var check   = function(role) {
        // var token   = window.localStorage.getItem('token');
        const token = utils.cookie.get('token_auth');
        return (role+'-'+sha1(role+'-'+token)) === signed;
    };

    if(!check(role)) {
        return window.app.logout();
    }

    if(role === 1) {
        $('.wrap-breadcrumb').find('a[data-role*="1"]').removeClass('hide');
        $('#notifications-menu').removeClass('hide');
    } else if(role === 2) {
        $('.wrap-breadcrumb').find('a[data-role*="2"]').removeClass('hide');
    } else if(role === 3) {
        $('.wrap-breadcrumb').find('a[data-role*="3"]').removeClass('hide');
    } else {
        $('body').addClass('hide');
        return window.app.logout();
    }

    var path    = window.location.pathname.split('/')[1];

    if(path !== 'notification' && path !== 'notifications') {
        if(role === 1) {

        } else if(role === 2 && (path !== 'dashboard' && path !== 'reports') ) {
            $('body').addClass('hide');
            return window.location.href = '/dashboard';
        } else if(role === 3 && path !== 'managers') {
            $('body').addClass('hide');
            return window.location.href = '/managers';
        }
    }

    $('body').hide().removeClass('hide').fadeIn();

};
