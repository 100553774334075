const stampit = require('stampit');


module.exports = stampit().methods({

    /**
     * Creates a new medium editor instance for a fragment.
     *
     * @param  {HTMLElement} element
     * @param  {Object} [conf]
     * @param  {Object|Boolean} [conf.toolbar]
     * @param  {Object} [conf.placeholder]
     * @param  {Function} [conf.quoter] - If this is sent, in the toolbar
     * will be added the option to convert this fragment to quote type.
     * @param  {Function} [conf.unquoter] - If this is sent, the toolbar options
     * will be changed to a quote type, including an option to convert the convert
     * the fragment to text type.
     * @param  {Boolean} [conf.disableReturn]
     * @param  {Boolean} [conf.isList]
     *
     * @return {Object} Medium editor instance.
     */
    mediumEditor: function (element, conf) {

        if (!element) {
            throw new Error('It is required a DOM node as first parameter.');
        }

        conf = _({
            disableReturn: false,
            quoter: false,
            unquoter: false,
            isList: false,
            spellcheck: true,
            placeholder: {
                text: 'Write your text...'
            },
            paste: {
                forcePlainText: false,
                cleanPastedHTML: false
            },
            toolbar: {
              buttons: [
                // {
                //   name: 'bold',
                //   contentDefault: '<i class="icon-format-bold"></i>',
                //   contentFA: '<i class="icon-format-bold"></i>'
                // },
                // {
                //   name: 'italic',
                //   contentDefault: '<i class="icon-format-italic"></i>',
                //   contentFA: '<i class="icon-format-italic"></i>'
                // }, 
                // {
                //   name: 'anchor',
                //   contentDefault: '<i class="icon-link"></i>',
                //   contentFA: '<i class="icon-link"></i>'
                // },
                // {
                //   name: 'h2',
                //   contentDefault: 'h2',
                //   contentFA: 'h2'
                // }, {
                //   name: 'h3',
                //   contentDefault: 'h3',
                //   contentFA: 'h3'
                // }
              ],
              allowMultiParagraphSelection: false
            }
          }
        )
        .extend(true, conf);

        // Quoter button.
        // let QuoterExtension;
        // if (conf.quoter) {
        //     conf.toolbar.buttons.push('quoter');
        //     QuoterExtension = MediumEditor.extensions.button.extend({
        //         name: 'quoter',
        //         tagNames: [],
        //         contentDefault: '<i class="icon-format-quote"></i>',
        //         contentFA: '<i class="icon-format-quote"></i>',
        //         aria: 'quote',
        //         action: 'quoter',
        //         handleClick: conf.quoter
        //     });
        // }

        // UnQuoter button.
        // let UnQuoterExtension;
        // if (conf.unquoter) {
        //     conf.toolbar.buttons = ['unquoter'];
        //     UnQuoterExtension = MediumEditor.extensions.button.extend({
        //         name: 'unquoter',
        //         tagNames: [],
        //         contentDefault: '<i class="icon-format-clear"></i>',
        //         contentFA: '<i class="icon-format-clear"></i>',
        //         aria: 'unquote',
        //         action: 'unquoter',
        //         handleClick: conf.unquoter
        //     });
        // }

        const editorSettings = {
            toolbar: conf.toolbar,
            placeholder: conf.placeholder,
            elementsContainer: this.$container[0],
            disableReturn: conf.disableReturn,
            disableDoubleReturn: conf.disableReturn,
            paste: conf.paste,
            disableExtraSpaces: false,
            targetBlank: true,
            autoLink: true,
            imageDragging: false
        };
        // if (conf.quoter) {
        //     editorSettings.extensions = {
        //         quoter: new QuoterExtension()
        //     };
        // }
        // if (conf.unquoter) {
        //     editorSettings.extensions = {
        //         quoter: new UnQuoterExtension()
        //     };
        // }

        const editor = new MediumEditor(element, editorSettings);
        editor.cfeConfig = conf;
        editor.cfe = this;

        // When the user types, clean content.
        editor.subscribe('editableInput', () => this.sanitizeEditorContent(editor));
        setTimeout(() => editor.trigger('editableInput', {}, element), 0);

        return editor;
    },

    /**
     * Add general medium editor events to the editor element.
     * @param  {Backbone.Model} model - The fragment model which has the editor.
     */
    mediumEditorEvents: function (model) {

        const editor = model.view.editor;
        const onKeyDown = e => {
            const key = e.keyCode || e.charCode;
            switch (key) {
                // case this.KEY_TAB:
                //     this.mediumEditorEventKeyTab(model, e);
                //     break;
                // case this.KEY_ENTER:
                //     this.mediumEditorEventKeyEnter(model, e);
                //     break;
                // case this.KEY_UP:
                // case this.KEY_LEFT:
                //     this.mediumEditorEventKeyLeftOrUp(model, e);
                //     break;
                // case this.KEY_DOWN:
                // case this.KEY_RIGHT:
                //     this.mediumEditorEventKeyRightOrDown(model, e);
                //     break;
                case this.KEY_BACKSPACE:
                    this.mediumEditorEventKeyBackspace(model, e);
                    break;
                // case this.KEY_DELETE:
                //     this.mediumEditorEventKeyDelete(model, e);
                //     break;
            }
        };

        editor.subscribe('editableKeydown', onKeyDown);
        editor.subscribe('editablePaste', e => this.mediumEditorEventPaste(model, e));
        editor.subscribe('editableBlur', e => this.mediumEditorEventBlur(model, e));
    },

    mediumEditorEventKeyTab (model, e) {
        const editor = model.view.editor;
        const nodeEditor = editor.elements[0];
    },

    // mediumEditorEventKeyEnter (model, e) {

    //     const editor = model.view.editor;
    //     const nodeEditor = editor.elements[0];
    //     const position = this.getFragmentPosition(model);
    //     const content = this.getEditorContent(editor, true);
    //     const caret = this.getContentEditableCaretOffset(nodeEditor);
    //     const currentSel = editor.exportSelection();

    //     if (model.get('type') !== 'list') {

    //         const tag = model.get('type') === 'quote' ?
    //             'blockquote' :
    //             model.get('content').trim().length ?
    //                 $(model.get('content'))[0].nodeName.toLowerCase() :
    //                 'p';

    //         editor.importSelection({ start: 0, end: currentSel.start });
    //         const firstSplit = rangy.getSelection().toHtml();

    //         editor.importSelection({ start: currentSel.end, end: content.length });
    //         const lastSplit = rangy.getSelection().toHtml();

    //         // Remove the user selection.
    //         editor.importSelection(currentSel);
    //         rangy.getSelection().deleteFromDocument();

    //         // Caret is at the beginning
    //         if (!$('<div>').html(firstSplit).text().length) {
    //             this.create(null, {
    //                 position: position === 0 ? 'first' : position-1,
    //                 focus: false
    //             });
    //         }

    //         // Caret is at the end
    //         else if (!$('<div>').html(lastSplit).text().length) {
    //             this.create({}, { position, focus: true });
    //         }

    //         // Caret is in the middle
    //         else {
    //             this.replace(model, {
    //                 content: `<${tag}>${firstSplit}</${tag}>`
    //             });
    //             this.create({
    //                 type: model.get('type'),
    //                 content: `<${tag}>${lastSplit}</${tag}>`
    //             }, {
    //                 position,
    //                 focus: true
    //             });
    //         }
    //     } else {

    //         // Caret is at the end
    //         if (currentSel.end === content.length) {

    //             // Selection is inside list
    //             const isParentList = this.findSelectionParent('UL') || this.findSelectionParent('OL');
    //             if (!isParentList) {
    //                 this.create({}, { position, focus: true });
    //             }
    //         }
    //     }
    // },

    mediumEditorEventKeyLeftOrUp (model, e) {

        const editor = model.view.editor;
        const nodeEditor = editor.elements[0];
        const position = this.getFragmentPosition(model);
        const content = this.getEditorContent(editor, true);
        const caret = this.getContentEditableCaretOffset(nodeEditor);

        if (caret === 0) {
            if (position === 0) {
                if (content.length) {
                    this.create(null, { focus: true, position: 'first' });
                    e.preventDefault();
                }
            } else {
                const prev = this.getPrevFragment(model);
                this.perform('focus', prev, { end: true });
                e.preventDefault();
            }
        }
    },

    mediumEditorEventKeyRightOrDown (model, e) {

        const editor = model.view.editor;
        const nodeEditor = editor.elements[0];
        const position = this.getFragmentPosition(model);
        const content = this.getEditorContent(editor, true);
        const caret = this.getContentEditableCaretOffset(nodeEditor);

        if (caret === content.length) {
            if (position === this.fragments.length-1) {
                if (content.length) {
                    this.create(null, { focus: true });
                    e.preventDefault();
                }
            } else {
                const next = this.getNextFragment(model);
                this.perform('focus', next, { start: true });
                e.preventDefault();
            }
        }
    },

    mediumEditorEventKeyBackspace (model, e) {

        const editor = model.view.editor;
        const nodeEditor = editor.elements[0];
        const position = this.getFragmentPosition(model);
        const content = this.getEditorContent(editor, true);
        const caret = this.getContentEditableCaretOffset(nodeEditor);
        const prevModel = this.getPrevFragment(model);

        if (!content.length) {
            // this.remove(model);
            e.preventDefault();
        }
        else if (position !== 0 && caret === 0 && prevModel.get('type') === model.get('type')) {

            if (!prevModel.get('content').trim().length) {
                this.remove(prevModel, { focus: false });
                return;
            }

            let $prevContent;
            let $currentContent;

            if (model.get('type') === 'quote') {
                $prevContent = $(`<blockquote>${prevModel.get('content')}</blockquote>`);
                $currentContent = $(`<blockquote>${model.get('content')}</blockquote>`);
            } else {
                $prevContent = $(prevModel.get('content'));
                $currentContent = $(model.get('content'));
            }

            const prevTag = $prevContent[0].nodeName.toLowerCase();
            const currentTag = $currentContent[0].nodeName.toLowerCase();

            if (prevTag === currentTag) {
                this.remove(model);
                const newModel = this.replace(prevModel, {
                    content: `<${prevTag}>`+ $prevContent.html() + $currentContent.html() +`</${prevTag}>`
                });
                const caret = $prevContent.text().length;
                this.focusEditor(newModel.view.editor, { caret });
                e.preventDefault();
            }
        }
    },

    mediumEditorEventKeyDelete (model, e) {

        const editor = model.view.editor;
        const content = this.getEditorContent(editor, true);

        if (!content.length) {
            e.preventDefault();
            this.remove(model);
        }
    },

    /**
     * The paste event by default in medium editor can fulfill our necessities,
     * but we are working with different editors in each fragment, so we
     * need to handle the paste event in a different way.
     */
    mediumEditorEventPaste (model, event) {

        if (model.get('type') === 'list') return;

        var editor = model.view.editor;

        var paragraphs,
            html = [],
            p,
            dataFormatHTML = 'text/html',
            dataFormatPlain = 'text/plain',
            pastedHTML,
            pastedPlain;

        if (window.clipboardData && event.clipboardData === undefined) {
            event.clipboardData = window.clipboardData;
            // If window.clipboardData exists, but event.clipboardData doesn't exist,
            // we're probably in IE. IE only has two possibilities for clipboard
            // data format: 'Text' and 'URL'.
            //
            // Of the two, we want 'Text':
            dataFormatHTML = 'Text';
            dataFormatPlain = 'Text';
        }

        // if (event.clipboardData && event.clipboardData.getData && !event.defaultPrevented) {
        //     event.preventDefault();

        //     pastedPlain = event.clipboardData.getData(dataFormatPlain);
        //     paragraphs = pastedPlain.split(/[\r\n]+/g);

        //     // If there are no \r\n in data, don't wrap in <p>
        //     if (paragraphs.length > 1) {
        //         for (p = 0; p < paragraphs.length; p += 1) {
        //             if (paragraphs[p] !== '') {
        //                 html.push(MediumEditor.util.htmlEntities(paragraphs[p]));
        //             }
        //         }
        //     } else {
        //         html.push(MediumEditor.util.htmlEntities(paragraphs[0]));
        //     }

        //     html = html.map(item => item.replace(/\s{1,}/g, ' '));
        //     html = html.reverse();

        //     const position = this.getFragmentPosition(model);
        //     const firstPaste = html[html.length-1];
        //     const currentSel = editor.exportSelection() || { start: 0, end: 0 };

        //     // Delete the current selection.
        //     rangy.getSelection().deleteFromDocument();

        //     // Get the first half part of the content until selection.
        //     editor.importSelection({
        //         start: 0,
        //         end: currentSel.start
        //     });
        //     const firstContent = rangy.getSelection().toHtml();

        //     // Get second half part of the content from selection.
        //     const contentLength = this.getEditorContent(editor, true).length;
        //     editor.importSelection({
        //         start: currentSel.start,
        //         end: contentLength
        //     });
        //     const lastContent = rangy.getSelection().toHtml();

        //     // We replace the current model with a new one in both cases, so
        //     // we have to reference the new fragment and its editor.

        //     // If there is one chuck of text to paste.
        //     if (html.length === 1) {

        //         const newCurrentContent = firstContent + firstPaste + lastContent;
        //         model = this.replace(model, { content: newCurrentContent });
        //         editor = model.view.editor;

        //         const caret = $(`<p>${firstContent + firstPaste}</p>`).text().length;
        //         this.focusEditor(editor, { caret });
        //     }

        //     // If there are more chucks of text to paste.
        //     else {

        //         const newCurrentContent = firstContent + firstPaste;
        //         model = this.replace(model, { content: newCurrentContent });
        //         editor = model.view.editor;

        //         const caret = html[0].length;

        //         html[0] = html[0] + lastContent;

        //         let first = true;
        //         for (let i=0, j=html.length-1; i<j; i++) {
        //             const newModel = this.create({
        //                 content: `<p>${html[i]}</p>`
        //             }, {
        //                 position,
        //                 focus: false
        //             });
        //             if (first) {
        //                 this.focusEditor(newModel.view.editor, { caret });
        //             }
        //             first = false;
        //         }
        //     }
        // }
    },

    mediumEditorEventBlur (model, e) {
        const editor = model.view.editor;
        this.cleanEditorContent(editor);
    }
});
