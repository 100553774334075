const stampit = require('stampit');

module.exports = stampit().methods({
    /**
     * Record and optionally log a message for debug information.
     *
     * @param  {String} msg - A debug message.
     */
    log (msg) {

        this.records.push({
            msg,
            datetime: (new Date()).toString()
        });

        if (!this.debug) return;

        if (console.debug) {
            console.debug('CFE: '+ msg);
        } else {
            console.log('CFE: '+ msg);
        }
    },

    /**
     * Executes a method in a fragment. When we want to execute an method in
     * a fragment but we don't know what kind it is, we execute this method
     * and we determine how to perform it.
     *
     * @param  {String} method - The method name.
     * @param  {Backbone.Model} model - The fragment model.
     * @param  {Object} [conf] - An optional configuration.
     */
    perform (method, model, conf={}) {

        const type = model.get('type');
        const controllerPrefix = 
              type === 'quote' ? 'fragmentQuote'
            : type === 'image' ? 'fragmentImage'
            : type === 'list' ? 'fragmentList'
            : type === 'question' ? 'fragmentQuestion'
            : type === 'transition' ? 'fragmentTransition'
            : type === 'text_image' ? 'fragmentTextImage'
            : type === 'video' ? 'fragmentVideo'
            : 'fragmentText';
        const controllerSufix = 'Trigger';

        this[controllerPrefix + controllerSufix](method, model, conf);
    },

    /**
     * Set the cursor caret at an specific position within an editor content editable.
     *
     * @param  {MediumEditor} editor
     * @param  {Object} [conf]
     * @param  {Boolean} [conf.start] - If the caret will be in the beginning.
     * @param  {Boolean} [conf.end] - If the caret will be in the end.
     * @param  {Number} [conf.caret] - If the caret will be at an specific position.
     * Should be greater or equal than 0 and less than the total length.
     */
    focusEditor (editor, conf={}) {

        let selection = { start: 0, end: 0 };

        if (conf.end) {
            const length = this.getEditorContent(editor, true).length;
            selection = { start: length, end: length };
        }
        else if ($.isNumeric(conf.caret) && conf.caret > 0) {
            selection = { start: conf.caret, end: conf.caret };
        }

        // Clean all current selections.
        rangy.getSelection().removeAllRanges();

        editor.importSelection(selection);
        editor.checkSelection();
    },

    /**
     * Get a content editable element caret position. It can be within 0 (the start of
     * the content) until `textContent.length` (the text content length).
     *
     * @param {Node} element - The content editable element.
     */
    getContentEditableCaretOffset (element) {

        var caretOffset = 0;
        var doc = element.ownerDocument || element.document;
        var win = doc.defaultView || doc.parentWindow;
        var sel;

        if (typeof win.getSelection != "undefined") {
            sel = win.getSelection();
            if (sel.rangeCount > 0) {
                var range = win.getSelection().getRangeAt(0);
                var preCaretRange = range.cloneRange();
                preCaretRange.selectNodeContents(element);
                preCaretRange.setEnd(range.endContainer, range.endOffset);
                caretOffset = preCaretRange.toString().length;
            }
        } else if ( (sel = doc.selection) && sel.type != "Control") {
            var textRange = sel.createRange();
            var preCaretTextRange = doc.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToEnd", textRange);
            caretOffset = preCaretTextRange.text.length;
        }

        return caretOffset;
    },

    sanitizeEditorContentGeneral (editor) {

        const $element = $(editor.elements[0]);

        // Ensure links to have their scheme.
        let href;
        $element.find('a').each((i, a) => {
            href = $(a).attr('href');
            href = /^https?\:\/\//.test(href) ? href : `http://${href}`;
            $(a).attr('href', href);
        });
    },

    sanitizeEditorContentText (editor) {

        const $el = $(editor.elements[0]);
        const $p = $el.find('p');

        // $el.find('div, span').each((i, e) => {
        //     if (!$(e).text().trim().length) {
        //         $(e).remove();
        //     }
        // });
        // if ($p.length > 1) {
        //     $p.each((i, e) => {
        //         if (!$(e).text().trim().length) {
        //             $(e).remove();
        //         }
        //     });
        // }

        let $tag = $p.length ? $p.first() : null;
        let nodeName = $tag ? 'p' : null;

        const $customs = $el.find('h2, h3, h4, h5, h6');

        if ($customs.length) {
            $tag = $customs.first();
            nodeName = $customs.first()[0].nodeName.toLowerCase();
        }

        // if ($tag) {
        //     const $real = $tag.find(`${nodeName}:not(:has(${nodeName}))`);
        //     if ($real.length) {
        //         $el.html('').append($real);
        //     }
        // }

        // if ($p.length > 1) {
        //     const $newP = $('<p>');
        //     $p.each((i, p) => $newP.append($(p).html()));
        //     $el.html('').html($newP);
        // }
    },

    sanitizeEditorContentList (editor) {

        const $el = $(editor.elements[0]);
        const $containsList = $el.find('ul, ol');
        const tag = $containsList.length ? $containsList.first()[0].nodeName : 'UL';

        // Replace paragraphs with spans
        // $el.find('p').each((i, p) => {
        //     $(p).replaceWith($('<span>').html($(p).html() + '&nbsp;'));
        // });

        // Ensure there are no split lists
        const $contents = $el.contents();
        if ($contents.length > 1) {
            let caret = editor.exportSelection();
            caret = caret ? caret.start : null;
            const $newTag = $(document.createElement(tag));
            $contents.each((i, child) => {
                if (child.nodeType === 3 || child.nodeName === 'P' || child.nodeName === 'SPAN') {
                    $newTag.append('<li>'+ child.textContent +'</li>');
                }
                else if (child.nodeName !== 'BR') {
                    $newTag.append($(child).clone().contents());
                }
            });
            // FIXIT: The editor meddles with our synchronously edition here, so we
            // postpone it.
            setTimeout(() => {
                $el.html($newTag);
                if (caret) {
                    editor.importSelection({ start: caret, end: caret });
                }
            }, 200);
        }

        // Ensure there is a list
        if (!$containsList.length) {
            $el.html('<ul><li></li></ul>');
            $el.find('li:first').append(document.createTextNode(''));
        }
    },

    /**
     * Maintain an editor content editable element without unnecesary elements which
     * are obious to be removed.
     *
     * This is mostly used meanwhile the user is editing the content.
     *
     * @param  {MediumEditor} editor - A medium editor.
     */
    sanitizeEditorContent (editor) {

        const conf = editor.cfeConfig;

        this.sanitizeEditorContentGeneral(editor);

        if (conf.isList) {
            this.sanitizeEditorContentList(editor);
        } else {
            this.sanitizeEditorContentText(editor);
        }
    },

    /**
     * Clean an editor content editable from HTML tags and attributes wrongly
     * created. This is to ensure it creates properly the content of the chapter.
     *
     * @param  {MediumEditor} editor
     */
    cleanEditorContent (editor) {

        const $element = $(editor.elements[0]);

        $element.find('p, div, span, br').each((i, e) => {
            if (!$(e).text().trim().length) {
                $(e).remove();
            }
        });

        if (!$element.contents().length) {
            $element.append(document.createTextNode(''));
        }
    },

    /**
     * Clean the content of an input or textarea field.
     *
     * @param  {jQuery} $input
     */
    cleanInput ($input, allowBreaks = false) {
        let newValue = $input.val();

        if (!allowBreaks) {
            newValue = newValue.replace(/\r?\n\r?/g, ' ')
                                .replace(/\s{1,}/g, ' ')
        }
            
        newValue = newValue.trim();
        $input.val(newValue);
    },

    /**
     * Get an editor content editable data.
     *
     * @param  {MediumEditor} editor
     * @param  {Boolean} asText - Convert the content in plain text.
     *
     * @return {String}
     */
    getEditorContent (editor, asText) {

        const $el = $(editor.elements[0]);

        if (asText) {
            return $el.text().trim();
        }

        let content = '';
        const items = $el.
            contents().
            filter((i, item) => item.nodeType === 1 || item.nodeType === 3).
            filter((i, item) => $(item).text().trim().length).
            map((i, item) => {
                if (item.nodeType === 3) {
                    return item.textContent;
                } else {
                    const $clone = $(item).clone().removeAttr('style');
                    $(item).find('*').removeAttr('style');
                    return $('<div>').append($clone).html();
                }
            });

        for (let i=0, j=items.length; i<j; i++) {
            content += items[i];
        }

        content = content.trim();

        if (!content.length) {
            return '';
        }

        // Content does not contain subtitles nor lists. So it should be a paragraph.
        if (!$el.find('h2, h3, h4, h5, h6, ul, ol').length && !$el.find('p').length) {
            content = `<p>${content}</p>`;
        }

        return content;
    },

    /**
     * Get to know if a fragment is empty.
     *
     * @param  {Backbone.Model}  model - Fragment model.
     * @return {Boolean}
     */
    isFragmentEmpty (model) {

        const type = model.get('type');
        const content = model.get('content');
        const url = model.get('url');
        const title = model.get('title');

        switch (type) {
            case 'text':
            case 'quote':
                if (!content.length) return true;
                break;
            case 'list':
                if (!content.length && (!title || !title.length)) return true;
                break;
            case 'image':
                if (!content.length && (!url || !url.length)) return true;
                break;
            case 'transition':
            case 'text_image':
                if (!content.length && (!url || !url.length) && (!title || !title.length)) return true;
                break;
            case 'video':
                if (!content.length && (!url || !url.product || !url.product.length)) return true;
                break;
        }

        return false;
    },

    /**
     * Get a fragment content.
     *
     * @param  {Backbone.Model} model
     * @param  {Boolean} asText - Convert to plain text.
     * @return {String}
     */
    getFragmentContent (model, asText) {
        let content = typeof model.get('content') === 'string' ? model.get('content') : '';
        content = content.trim();
        if (asText) {
            content = $('<div>').html(content).text();
        }
        return content;
    },

    /**
     * Get the current window selection text.
     *
     * @return {String}
     */
    getSelectionText () {

        const sel = window.getSelection();

        let html = "";
        let text = "";

        if (sel.rangeCount) {
            const container = document.createElement("div");
            for (let i = 0, len = sel.rangeCount; i < len; ++i) {
                container.appendChild(sel.getRangeAt(i).cloneContents());
            }
            text = container.textContent;
            html = container.innerHTML;
        }

        return text;
    },

    /**
     * Swap a fragment position.
     *
     * @param  {Backbone.Model} model
     * @param  {String} direction
    */
    swapFragment (model, direction) {
        let position;
        let currentID = this.getFragmentPosition(model);
        const nextID = this.fragments.models.length == currentID ? currentID : currentID + 1;
        const prevID = currentID === 0 ? 0 : currentID - 1;
        const swap = (array, i, j) => [array[i], array[j]] = [array[j], array[i]];
        
        if(direction == "up") {
            swap(this.fragments.models, prevID, currentID)
            position = prevID;
        } else {
            swap(this.fragments.models, currentID, nextID)
            position = nextID;
        }

        return position
    },

    /**
     * Get a fragment position.
     *
     * @param  {Backbone.Model} model
     * @return {Number}
     */
    getFragmentPosition (model) {
        const fragments = this.fragments;
        const id = model.get('id');
        return fragments.findIndex(fragment => fragment.get('id') === id);
    },

    /**
     * Get the next fragment of a fragment provided. Returns null if no next fragment.
     *
     * @param  {Backbone.Model} model
     * @return {Backbone.Model}
     */
    getNextFragment (model) {
        const fragments = this.fragments;
        const id = model.get('id');
        const index = fragments.findIndex(fragment => fragment.get('id') === id);
        if (index === fragments.length - 1) return null;
        return fragments.at(index + 1);
    },

    /**
     * Get the previous fragment of a fragment provided. Returns null if no
     * previous fragment.
     *
     * @param  {Backbone.Model} model
     * @return {Backbone.Model}
     */
    getPrevFragment (model) {
        const fragments = this.fragments;
        const id = model.get('id');
        const index = fragments.findIndex(fragment => fragment.get('id') === id);
        if (index === 0) return null;
        return fragments.at(index - 1);
    },

    /**
     * Get to know if a fragment is custom type.
     *
     * @param  {Backbone.Model}  model
     * @return {Boolean}
     */
    isCustomFragment (model) {
        const type = model.get('type');
        if (type === 'image' || type === 'video') return true;
        return false;
    },

    /**
     * Validate custom fragments from being overlapped. If two custom fragments
     * are aligned to the left in the same row, we have to separate them. Otherwise
     * one of them will be on top of the other.
     *
     * @param  {Backbone.Model} model
     */
    validateMultipleCustomFragments (model) {

        if (!this.isCustomFragment(model)) {
            return;
        }

        const position = this.getFragmentPosition(model);

        if (this.fragments.length === 1) {
            return;
        }

        if (position !== 0) {
            const prev = this.getPrevFragment(model);
            if (this.isCustomFragment(prev) &&
            model.get('align') === 'left' && prev.get('align') === 'left') {
                this.fixTwoCustomFragmentsOverlapped(model, prev);
            }
        }

        if (position !== this.fragments.length-1) {
            const next = this.getNextFragment(model);
            if (this.isCustomFragment(next) &&
            model.get('align') === 'left' && next.get('align') === 'left') {
                this.fixTwoCustomFragmentsOverlapped(model, next);
            }
        }
    },

    /**
     * This is used to separate two custom fragments overlaped in the same row.
     *
     * @param  {Backbone.Model} toFix - The framgnet to fix.
     * @param  {Backbone.Model} alreadyPlaced - The fragment which is already placed
     * properly.
     */
    fixTwoCustomFragmentsOverlapped (toFix, alreadyPlaced) {
        setTimeout(() => {
            toFix.set('align', 'center');
            this.perform('align', toFix);
            this.perform('flick', alreadyPlaced);
        }, 50);
    },

    /**
     * Find an element with an specific nodeName as parent of the current selection.
     * @param  {String} nodeName
     * @return {Node|null}
     */
    findSelectionParent (nodeName) {
        const sel = rangy.getSelection();
        let el = sel.getRangeAt(0).startContainer;
        while (true) {
            el = el.parentNode;
            if (el.nodeName === nodeName) {
                break;
            }
            if (el.nodeName === 'BODY') {
                return null;
            }
        }
        return el;
    }
});