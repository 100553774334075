const stampit = require('stampit');
const autosize = require('autosize');
const config = require('../../config');
const utils = require('../../utils');
const template = _.template(require('../../../templates/chapter-fragments-editor/video.html'));
const ui = require('sharedComponents/js/ui');
const Flash = require('components/flash');

module.exports = stampit().methods({
    /**
     * Create new fragment video with the model and view container created.
     *
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentVideo: function (model, conf) {

        const view = model.view;
        const $el = model.view.$el;

        // Set fragment inner template.
        const content = this.getFragmentContent(model);
        $el.find('.'+ this.CLASS_FRAGMENT_CONTENT).html(template({ content }));

        //
        // REMOVE
        //
        const constants = config.CMS.FRAGMENTS.DELETE;

        $el.find('.'+ this.CLASS_FRAGMENT_OPT_REMOVE).on('click', e => {
            $el.hide(0);

            ui.$createModal('confirm', {
            title: constants.TITLE,
            description: constants.DESCRIPTION,
            buttonAccept: constants.ACCEPT,
            buttonCancel: constants.CANCEL,
            onAccept: e => {
                this.remove(model);
            },
            onCancel: e => {
                $el.show(0);
            }
            });
        });

        /*
        * Reorder
        */
        $el.find('.button-order_up, .button-order_down').on('click', e => {
            let position;

            if ($(e.target)[0].dataset.direction == "up") {
                position = this.swapFragment(model, 'up');
            } else {
                position = this.swapFragment(model, 'down');
            }
            
            model.set({ position })
            setTimeout(() => { location.reload(); }, 750)
        });

        //
        // ALIGN
        //
        this.fragmentVideoTrigger('align', model);

        $el.find('.'+ this.CLASS_EDITOR_ALIGN_OPTION).on('click', e => {
            const align = $(e.target).data('action');
            model.set({ align });
            this.fragmentVideoTrigger('align', model);
        });

        //
        // VIDEO URL
        //
        const token = utils.cookie.get('token_auth')
        // const token = window.localStorage.getItem('token');
        const chapterId = this.chapterModel.get('id');
        const desktopVideo = $el[0].querySelector('#desktopResult');
        const mobileVideo = $el[0].querySelector('#mobileResult');
        const desktopInputFile = $el[0].querySelector('input[id="desktopVideo"]');
        const mobileInputFile = $el[0].querySelector('input[id="mobileVideo"]');
        desktopInputFile.addEventListener('change', handleDesktopFiles, false);
        mobileInputFile.addEventListener('change', handleMobileFiles, false);

        const desktopUrl = model.get('desktop_video');
        if((typeof desktopUrl !== 'undefined') && (desktopUrl !== null)) {
            desktopVideo.src = config.API.WEBSITE + desktopUrl;
        }
        const mobileUrl = model.get('mobile_video');
        if((typeof mobileUrl !== 'undefined') && (mobileUrl !== null)) {
            mobileVideo.src = config.API.WEBSITE + mobileUrl;
        }

        function handleDesktopFiles() {
            const desktopInputFile = $el[0].querySelector('input[id="desktopVideo"]');
            const file = desktopInputFile.files[0];
            if(!file) {
                Flash.error("Select a file");
                return;
            }
            const progress = $el[0].querySelector('#desktopProgress');
            const result = $el[0].querySelector('#desktopResult');

            fetch(`${config.API.SERVER}/chapters/${chapterId}/upload`, {
                method: "POST",
                body: JSON.stringify({ filename: file.name }),
                headers: {
                    'x-token-auth' : token
                }
            }).then(function(r) {
                return r.json()
            }).then(function(json) {
                let data = new FormData();
                Object.entries(json.data.upload.fields).forEach(([k,v]) => {
                    data.append(k, v);
                })
                data.append('file', file); // Must be last field
                let request = new XMLHttpRequest();
                request.open('POST', json.data.upload.url);
                request.upload.addEventListener('progress', function(e) {
                    let percent_completed = (e.loaded / e.total)*100;
                    progress.innerHTML = Math.round(percent_completed) + "%"

                    if(Math.round(percent_completed) == 100) { progress.style.opacity = 0 }
                    else { progress.style.opacity = 1 }
                });
                request.addEventListener('load', function(e) {
                    // Upload done, file can be loaded at path
                    const urlDesktop = json.data.path;
                    result.src = config.API.WEBSITE + urlDesktop;

                    model.set({ desktop_video: urlDesktop });
                });
                request.send(data);
            }).catch(function (ex) {
                console.error(ex)
            });
        }

        function handleMobileFiles() {
            const mobileInputFile = $el[0].querySelector('input[id="mobileVideo"]');
            const file = mobileInputFile.files[0];
            if(!file) {
                Flash.error("Select a file");
                return;
            }
            const progress = $el[0].querySelector('#mobileProgress');
            const result = $el[0].querySelector('#mobileResult');

            console.log('handleMobileFiles', { result })

            fetch(`${config.API.SERVER}/chapters/${chapterId}/upload`, {
                method: "POST",
                body: JSON.stringify({ filename: file.name }),
                headers: {
                    'x-token-auth' : token
                }
            }).then(function(r) {
                return r.json()
            }).then(function(json) {
                console.log('response', { json })
                let data = new FormData();
                Object.entries(json.data.upload.fields).forEach(([k,v]) => {
                    data.append(k, v);
                })
                data.append('file', file); // Must be last field
                let request = new XMLHttpRequest();
                request.open('POST', json.data.upload.url);
                request.upload.addEventListener('progress', function(e) {
                    let percent_completed = (e.loaded / e.total)*100;
                    progress.innerHTML = Math.round(percent_completed) + "%"

                    if(Math.round(percent_completed) == 100) { progress.style.opacity = 0 }
                    else { progress.style.opacity = 1 }
                });
                request.addEventListener('load', function(e) {
                    // Upload done, file can be loaded at path
                    const urlMobile = json.data.path;
                    result.src = config.API.WEBSITE + urlMobile;

                    console.log('set mobile video:', urlMobile)
                    model.set({ mobile_video: urlMobile });
                });
                request.send(data);
            }).catch(function (ex) {
                console.error(ex)
            });
        }
    },

    /**
     * Methods to execute in a fragment video type.
     *
     * @param  {String} method
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentVideoTrigger: function (method, model, conf={}) {

        // Focus the main fragment content.
        const focus = () => {

            const $el = model.view.$el;
            const $input = $el.find('input');

            $input.trigger('focus');
            utils.win.scrollTo($el);
        };

        // Update the current fragment alignment.
        const align = () => {

            const $el = model.view.$el;
            const $textarea = $el.find('textarea');
            const align = model.get('align') ? model.get('align') : 'center';

            $el.find('.'+ this.CLASS_EDITOR_ALIGN_OPTION).removeClass('active');
            $el.find('.'+ this.CLASS_EDITOR_ALIGN_OPTION +`[data-action=${align}]`).addClass('active');

            if (align === 'left') {
                $el.addClass(this.CLASS_FRAGMENT_ALIGNED_LEFT);
            } else {
                $el.removeClass(this.CLASS_FRAGMENT_ALIGNED_LEFT);
            }

            setTimeout(() => autosize.update($textarea), 0);

            this.validateMultipleCustomFragments(model);
        };

        // Animation to show the user this fragment is placed where it is.
        const flick = () => {
            const $el = model.view.$el;
            $el.removeClass(this.CLASS_FRAGMENT_FLICK);
            focus();
            setTimeout(() => {
                $el.addClass(this.CLASS_FRAGMENT_FLICK);
            }, config.ANIM.TIME/2 + 10);
        };

        switch (method) {
            case 'focus':   focus(); break;
            case 'align':   align(); break;
            case 'flick':   flick(); break;
            default: this.log(`Fragment Image trigger not found "${method}".`);
        }
    }
});
