/**
 * Flash
 */

module.exports  = {

    /**
     * Container
     */
    target: '.flash-message',

    /**
     * Initialize
     */
    init: function(element) {
        this.target  = (element) || '.flash-message';
        if($(this.target+':first').size() < 1) {
            $('body').append('<div class="'+ this.target.replace('.', '') +'"></div>');
        }

        if (window.localStorage.getItem('flash-message')) {
        // if($.cookie('flash-message')) {
            $(this.target+':first').append(window.localStorage.getItem('flash-message'));
            // console.log('flash', window.localStorage.getItem('flash-message'));
            window.localStorage.setItem('flash-message', '')
            // $.removeCookie('flash-message', { path: '/' });
        }
        this.bind();
    },

    bind: function() {
        $('body').on('click', '.alert-box .close', function(e) {
            e.preventDefault();
            $(this).parents('.alert-box:first').hide();
        });
    },

    /**
     * Show Error Message
     *
     * @param String msg
     * @param Mixing cb
     */
    error: function (msg, cb) {
        this.display('alert', msg, cb);
    },

    /**
     * Show Valid Message
     *
     * @param String msg
     * @param Mixing cb
     */
    valid: function (msg, cb) {
        this.display('success', msg, cb);
    },

    /**
     * Show Info Message
     *
     * @param String msg
     * @param Mixing cb
     */
    info: function (msg, cb) {
        this.display('info', msg, cb);
    },

    /**
     * Show Warning Message
     *
     * @param String msg
     * @param Mixing cb
     */
    warning: function (msg, cb) {
        this.display('warning', msg, cb);
    },

    /**
     *
     * @param String type
     * @param String msg
     * @param Mixing cb
     */
    display: function (type, msg, cb) {

        var tmp_id = Math.floor(Math.random()*11);
        var delay	= 7000;
        var element, script;
        if(cb!==undefined) {
            if (typeof cb === "function") {
                element	= '<div id="alert-id-'+tmp_id+'" data-alert class="alert-box callout radius ' + type + '"><a href="#" class="close">&times;</a><i class="icon-warning"></i><i class="icon-check-box"></i><i class="icon-error"></i><i class="icon-info"></i>'+msg+'</div>';
                script	= '<script type="text/javascript">$("#alert-id-'+tmp_id+'").delay('+7000+').fadeOut(500);</script>';
                window.localStorage.setItem('flash-message', element+script)
                // $.cookie('flash-message', element+script, { path: '/' });
                setTimeout(function() { cb(); }, 100);
                return;
            } else {
                delay	= cb;
            }
        }
        this.clear();
        element	= '<div id="alert-id-'+tmp_id+'" data-alert class="alert-box radius callout ' + type + '"><a href="#" class="close">&times;</a><i class="icon-warning"></i><i class="icon-check-box"></i><i class="icon-error"></i><i class="icon-info"></i>'+msg+'</div>';
        script	= '<script type="text/javascript">if('+delay+' > 0) { $("#alert-id-'+tmp_id+'").delay('+delay+').fadeOut(500); } else { $("#alert-id-'+tmp_id+'").show(); }</script>';
        $(this.target+':first').append(element+script);
    },

    /**
     * Clear all flash
     */
    clear: function() {
        $(this.target).empty();
    }

};
