/**
 * Utility functions to create interactive ui components for the application in
 * general.
 */

const utils = require('./utils');
const templateModalBasic = _.template(require('../templates/ui/modal-basic.html'));
const templateModalConfirm = _.template(require('../templates/ui/modal-confirm.html'));


module.exports = {

    /**
     * Create a modal.
     * @param  {String} type
     * @param  {Object} props
     * @return {jQuery}
     */
    $createModal (type, props) {

        props = _({}).extend({
            id: 'modal-' + Date.now(),
            title: '',
            description: '',
            html: '',
            isWide: false,
            data: {},
            template: null,
            iconClose: false,
            iconCloseLink: false,
            button: false,
            buttonText: 'OK',
            buttonLink: false,
            buttonAccept: 'Accept',
            buttonCancel: 'Cancel',
            onAccept: null,
            onCancel: null,
            onOk: null,
            onClose: null,
        }, props);

        let $el;

        switch (type) {
            case 'confirm':
                $el = $(templateModalConfirm(props));
                break;
            case 'basic':
                $el = $(templateModalBasic(props));
                break;
            case 'custom':
                $el = $(props.template(props));
                break;
        }

        $(window).scrollTop(0);

        $($el).appendTo('body');

        $el.foundation();

        if (props.isWide) {
            $el.addClass('reveal_wide');
        }

        if (props.onAccept) {
            $el.find('.btn-confirm').on('click', props.onAccept);
        }
        if (props.onCancel) {
            $el.find('.btn-cancel').on('click', props.onCancel);
        }
        if (props.onOk) {
            $el.find('[data-closeok]').on('click', props.onOk);
        }
        if (props.onClose) {
            $el.find('[data-close]').on('click', props.onClose);
        }

        $el.foundation('open');

        const position = () => {

            const w = $el.outerWidth();
            const h = $el.outerHeight();

            const d = utils.win.getDims();
            const t = $(window).scrollTop();

            const left = (d.width / 2) - (w / 2);
            const top = t + (d.height / 2) - (h / 2);

            $el.css({ left, top });
        };

        $(window).on('resize', position);

        setTimeout(position, 0);
        setTimeout(position, 250);

        $el.on('closed.zf.reveal', e => {
            $(window).scrollTop(0);
            $el.remove();
            $(window).off('resize', position);
        });

        return $el;
    }

};
