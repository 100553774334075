const moment = require('moment');
const S = require('string');
const config = require('appConfig');
const utils = require('sharedComponents/js/utils');
const template = _.template(require('./templates/notifications.html'));

const UPDATE_INTERVAL = config.CMS.NOTIFICATIONS.STATUS.UPDATE_INTERVAL;
const MODIFIER_ACTIVE = 'notifications-menu_active';
const MODIFIER_HASITEMS = 'notifications-menu_hasitems';
const PROPS = ['registration', 'graduate', 'request'];


const data = new Backbone.Model({
  registration: 0,
  graduate: 0,
  request: 0
});


// const getNotifications = utils.general.throttle(function () {
//   app.apiAjax.request({
//     endpoint: '/notification/unread',
//     success: result => data.set(result.data)
//   });
// }, UPDATE_INTERVAL);


const methods = {

  /**
   * Change the value of the notifications instance.
   *
   * @param  {Number} newValue
   * @return {jQuery}
   */
  value: function (newValue) {

    this.find('.notifications-menu__icon').html(newValue);

    this[newValue ? 'addClass' : 'removeClass'](MODIFIER_HASITEMS);

    return this;
  },

  render: function () {

    const _this = this;

    const $container = $(template());
    this.html($container);

    this.find('.notifications-menu__item').on('click', function (e) {
      const $item = $(this);
      const category = $item.data('category');
      methods.hide.call(_this);
      window.location.href = '/notifications#category/'+ category;
      setTimeout(() => window.location.reload(), 500);
    });

    return this;
  },

  update: function () {

    const props = _(data.toJSON()).pick(PROPS);

    const value = _(props).reduce((sum, current) => sum + current);
    methods.value.call(this, value);

    _(props).each((val, category) => {
      this.
        find(`.notifications-menu__item[data-category=${category}]`)
          [val ? 'removeClass' : 'addClass']('notifications-menu__item_0').
        find('.notifications-menu__item__number').text(val);
    });

    return this;
  },

  toggle: function () {

    if (this.hasClass(MODIFIER_ACTIVE)) {
      methods.hide.call(this);
      methods.update.call(this);
    } else {
      methods.show.call(this);
    }

    return this;
  },

  show: function () {

    methods.update.call(this);

    this.addClass(MODIFIER_ACTIVE);

    return this;
  },

  hide: function () {

    this.removeClass(MODIFIER_ACTIVE);

    methods.update.call(this);

    return this;
  },

  isShown: function () {
    return this.hasClass(MODIFIER_ACTIVE);
  }
};


module.exports = function () {

  // const $el = $('#notifications-menu');

  // methods.render.call($el);

  // $el.on('click', '.notifications-menu__trigger, .notifications-menu__icon, a', function (e) {
  //   methods.toggle.call($el);
  // });

  // $('body').on('click', function (e) {
  //   if (!$(e.target).parents('.notifications-menu').length) {
  //     methods.hide.call($el);
  //   }
  // });

  // // Set an event listener to the current menus.
  // $('body .menu a').on('click', function (e) {
  //   methods.hide.call($el);
  // });

  // data.on('change', () => methods.update.call($el));

  // app.notificationsInterval = setInterval(getNotifications, UPDATE_INTERVAL);
  // getNotifications();
};
