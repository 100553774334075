const srcMain = require('../templates/select1/main.html');
const srcOption = require('../templates/select1/option.html');

const templateMain = _.template(srcMain);
const templateOption = _.template(srcOption);

const BSELECT1_CLASS = 'select1';
const BSELECT1_CHOSEN_CLASS = 'select1__chosen';
const BSELECT1_OPTIONS_CLASS = 'select1__options';
const BSELECT1_OPTION_CLASS = 'select1__option';
const BSELECT1_MODIFIER_SHOWN = 'select1_shown';

// Default configuration.
const defaultConf = {
    value: 1,
    options: [
        {id: 0, text: 'No',  icon: 'cancel',       iconColor: '#fd666b'},
        {id: 1, text: 'Yes', icon: 'check-circle', iconColor: '#69ca25'}
    ]
};

// Plugin methods.
const methods = {

    /**
     * Get the current value or set a new one.
     *
     * @param  {Number|String} [value] - The new value.
     * @return {jQuery|*} - The jQuery object of the selection or the current value
     * consulted.
     */
    value: function (value) {
        let returnValue = this;

        _(this).find(function (i) {
            const $i = $(i);

            // Set value.
            if (value !== undefined) {

                const conf = $i.data('bSelect1-conf');
                const $main = $i.find(`.${BSELECT1_CLASS}`);

                // Set the chosen option.
                const chosenOption = _.findWhere(conf.options, {id: value});
                if (!chosenOption) {
                    throw new Error(`bSelect1 can not find the value ${value} in options.`);
                }
                const $chosenOption = $(templateOption(chosenOption));
                $main.find(`.${BSELECT1_CHOSEN_CLASS}`).html($chosenOption);

                // Set the chosen option as the first option in the list.
                const $option = $main.find(`.${BSELECT1_OPTIONS_CLASS} .${BSELECT1_OPTION_CLASS}[data-id=${value}]`);
                $main.find(`.${BSELECT1_OPTIONS_CLASS}`).prepend($option);

                $i.data('bSelect1-value', value);
                return false;
            }

            // Get value.
            else {
                returnValue = $i.data('bSelect1-value');
                return true;
            }
        });

        return returnValue;
    },


    /**
     * Show or hide the selector.
     *
     * @param  {String} [method] - The optional method to do. If not provided, it
     * toggles the selector.
     * @return {jQuery} - The jQuery object.
     */
    toggle: function (method) {
        return this.each(function () {
            const $i = $(this);
            const conf = $i.data('bSelect1-conf');
            const $main = $i.find(`.${BSELECT1_CLASS}`);

            // Hide.
            if (method === 'hide' || (method === undefined && $main.hasClass(BSELECT1_MODIFIER_SHOWN))) {
                $main.removeClass(BSELECT1_MODIFIER_SHOWN);
            }

            // Show.
            else {
                $main.addClass(BSELECT1_MODIFIER_SHOWN);

                let $options = $main.find(`.${BSELECT1_OPTIONS_CLASS}`);

                // Get the dimentions of the elements involved in the position
                // of the selector to show.
                const optionsDims = {
                    width: $options.outerWidth(true)
                };
                const mainDims = {
                    width: $main.outerWidth(true)
                };

                $options.css({
                    left: $main.position().left + mainDims.width/2 - optionsDims.width/2,
                    top: $main.position().top
                });
            }
        });
    }
};


/**
 * Plugion API. Creates a mini selector with icons and short text messages.
 *
 * @param  {Object|String} [conf] - The optional configuration of the instance or
 * the method to apply in the current instance.
 * @return {*} - According to input.
 */
jQuery.fn.bSelect1 = function (conf) {

    // Determine if plugin is dealing with a method instead of a new instance.
    if (typeof conf === 'string') {

        if (methods[conf]) {

            // All items should have instantiated the plugin.
            if (!_.every(this, i => $(i).data('bSelect1'))) {
                return this;
            }

            const args = Array.prototype.slice.call(arguments, 1);
            return methods[conf].apply(this, args);
        } else {
            throw new Error(`bSelect1 method ${conf} is not recognized.`);
        }
    }

    // If this is a new instance, look for already instantiated objects in the
    // jQuery selection. If so, end this. If not, save a flag.
    if (_.some(this, i => $(i).data('bSelect1'))) {
        return this;
    }
    this.data('bSelect1', true);

    // Create the configuration and save it.
    conf = _.extend({}, defaultConf, conf);
    this.data('bSelect1-conf', conf);

    this.each(function () {

        const $i = $(this);

        //
        // DOM
        //
        const $main = $(templateMain());

        let $options = $();
        conf.options.forEach(o => {
            $options = $options.add($(templateOption(o)));
        });

        $main.find(`.${BSELECT1_OPTIONS_CLASS}`).html($options);

        $i.html($main);

        methods.value.call($i, conf.value);

        //
        // EVENTS
        //

        // Click on the chosen item.
        $main.find(`.${BSELECT1_CHOSEN_CLASS}`)
        .on('click', function (e) {

            methods.toggle.call($i);
        });

        // Mouse out of the options.
        $main.find(`.${BSELECT1_OPTIONS_CLASS}`)
        .on('mouseleave', function (e) {

            methods.toggle.call($i, 'hide');
        });

        // Click in the site.
        $('body').on('click', function (e) {
            if ($main.hasClass(BSELECT1_MODIFIER_SHOWN)) {
                methods.toggle.call($i, 'hide');
            }
        });

        // Click inside the select1 container.
        $main.on('click', (e) => {
            e.stopPropagation();
        });

        // Click on an option.
        let $option, value;
        $main.find(`.${BSELECT1_OPTIONS_CLASS}`)
        .on('click', `.${BSELECT1_OPTION_CLASS}`, function (e) {
            $option = $(e.target);

            // Get the option value or return if unknown.
            if ($option.is(`.${BSELECT1_OPTION_CLASS}`)) {
                value = $option.data('id');
            }
            else if ($option.parents(`.${BSELECT1_OPTION_CLASS}`).length) {
                value = $option.parents(`.${BSELECT1_OPTION_CLASS}`).data('id');
            } else {
                return;
            }

            methods.value.call($i, value);

            methods.toggle.call($i, 'hide');
        });
    });

    return this;
};
