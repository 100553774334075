const stampit = require('stampit');

const refs =    require('./core/refs');
const init =    require('./core/init');
const create =  require('./core/create');
const remove =  require('./core/remove');
const replace = require('./core/replace');

const options =         require('./options');
const mediumEditor =    require('./medium-editor');
const cfeUtils =        require('./utils');

const fragmentText =  require('./fragments/text');
const fragmentQuote = require('./fragments/quote');
const fragmentList = require('./fragments/list');
const fragmentImage = require('./fragments/image');
const fragmentVideo = require('./fragments/video');
const fragmentQuestion = require('./fragments/question');
const fragmentTransition = require('./fragments/transition');
const fragmentTextImage = require('./fragments/text_image');


/**
 * Chapter Fragments Editor.
 *
 * This component creates an editor of chapter fragments. The content of a
 * chapter is composed of fragments or parts. The fragment list is an array defining
 * each of the parts of the chapter content. Every fragment is an structured part of
 * the content.
 *
 * This component is exposed with a jQuery plugin which returns an instance class
 * to manage the editor. This class receives a chapter model and should only
 * change the chapter property called `content` which will contain the result of
 * the edition. The task to update in the server is not covered in the component.
 *
 * The editor makes use of a chapter model. The property `content` contains the
 * structured content of the chapter but as a string gotten from a JSON.stringify()
 * of the real content. This structure has the fragments collection whichs describes
 * every fragment as an object.
 *
 * There are normal and custom fragments. A *normal fragment* is just an editor
 * (medium editor) of a piece of text in a defined structure. For example, the
 * "fragment text" is a normal fragment because it only edit a paragraph of text.
 * A *custom fragment* has an special UI to edit the fragment and can have one or
 * more internal editors of text inside. For example: the "fragment video" has
 * to define the video URL, the description and the alignment, to do so, we need
 * an input to type the URL, a textarea to type the description and buttons to set
 * the alignment. This is just a way to define them.
 *
 * The editor also has a toolbar of options managed in the object property `options`.
 * This toolbar is to let the user create new *custom fragments*. The
 * *normal fragments* should be created manually by typing or by the editor toolbar
 * buttons.
 *
 * Libraries:
 * - Stampit https://github.com/stampit-org/stampit
 * - MediumEditor https://github.com/yabwe/medium-editor
 *
 * TODO: Currently does not work on Firefox 45 nor IE 11.
 *
 * @param  {Object} conf - Configuration.
 * @param  {Boolean} conf.debug - If the editor is created in debug mode.
 * @param  {jQuery} conf.$element - The element to hold the fragments.
 * @param  {Backbone.Model} conf.chapterModel - The chapter model when the fragments
 * belong to.
 */
const chapterFragmentsEditor = stampit.compose(

    refs,
    init,
    create,
    remove,
    replace,
    options,
    mediumEditor,
    cfeUtils,

    fragmentText,
    fragmentQuote,
    fragmentList,
    fragmentImage,
    fragmentVideo,
    fragmentQuestion,
    fragmentTransition,
    fragmentTextImage
    
);


/**
 * Plugin API.
 *
 * @param  {Object} conf - Configuration.
 * @return {Object} - Controller.
 */
jQuery.fn.chapterFragmentsEditor = function (conf) {
    // USE other than the default locale

    // conf = _({lang: $.locale()}).extend({
    //     $element: this
    // }, conf);

    conf = _({lang: $.cmsLocale()}).extend({
        $element: this
    }, conf);

    console.log('ChapterFragmentsEditor', { conf })

    return chapterFragmentsEditor(conf);
};
