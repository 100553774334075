const stampit = require('stampit');
const utils = require('../../utils');
const autosize = require('autosize');
const template = _.template(require('../../../templates/chapter-fragments-editor/list.html'));
const itemTpl = _.template(require('../../../templates/chapter-fragments-editor/list_item.html'));
const ui = require('sharedComponents/js/ui');

module.exports = stampit().methods({
    /**
     * Create new fragment text with the model and view container created.
     *
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentList: function (model, conf = {}){

        const _this = this;
        const $el = model.view.$el;

        // Set fragment inner template.
        const c = this.getFragmentContent(model);
        $el.find('.'+ this.CLASS_FRAGMENT_CONTENT).html(template({ content: c }));
        const $fragment = $el.find('.'+ this.CLASS_FRAGMENT_CONTENT);

        // // Define the editor configuration.
        // const nodeEditor = $el.find('.'+ this.CLASS_EDITOR)[0];
        // const editorSettings = {
        //     quoter: function (e) {
        //         const editor = this.base;
        //         const content = _this.getEditorContent(editor, true);
        //         _this.replace(model, {
        //             type: 'quote',
        //             content: content
        //         });
        //     }
        // };

        // editorSettings.placeholder = {
        //     text: 'Type your list...'
        // };
        // // editorSettings.isList = true;

        // console.log('editorSettings', editorSettings);

        // // Create the editor.
        // const editor = this.mediumEditor(nodeEditor, editorSettings);
        // model.view.editor = editor;
        // model.view.nodeEditor = nodeEditor;

        // console.log('editor', editor);

        //
        // REMOVE
        //
        const constants = config.CMS.FRAGMENTS.DELETE;

        $el.find('.'+ this.CLASS_FRAGMENT_OPT_REMOVE).on('click', e => {
            $el.hide(0);

            ui.$createModal('confirm', {
            title: constants.TITLE,
            description: constants.DESCRIPTION,
            buttonAccept: constants.ACCEPT,
            buttonCancel: constants.CANCEL,
            onAccept: e => {
                this.remove(model);
            },
            onCancel: e => {
                $el.show(0);
            }
            });
        });

        /*
        * Reorder
        */
        $el.find('.button-order_up, .button-order_down').on('click', e => {
            let position;

            if ($(e.target)[0].dataset.direction == "up") {
            position = this.swapFragment(model, 'up');
            } else {
            position = this.swapFragment(model, 'down');
            }
            
            model.set({ position })
            setTimeout(() => { location.reload(); }, 750)
        });

        //
        // TITLE
        //
        const $title = $fragment.find('textarea[name="title"]');

        autosize($title);
        if(model.attributes.title) {
            $title.val(model.attributes.title);
        }
        setTimeout(() => autosize.update($title), 0);

        $title
            .on('change', e => {
                this.cleanInput($title, true);
                const title = $title.val();
                model.set({ title });
            })


        //
        // CONTENT
        //
        const maxItems = 5;
        let nbItems = 0;

        const initTextarea = ($textarea) => {
            autosize($textarea);
            setTimeout(() => autosize.update($textarea), 0);

            $textarea
            .on('change', e => {
                this.cleanInput($textarea, true);

                const $textareas = $el.find('.list-items textarea');
                const array = [];
                $textareas.each((index, textarea) => {
                    array.push($(textarea).val())
                })
                const content = `<ul><li>${ array.join('</li><li>') }</li></ul>`;

                model.set({ content });
            })
            .on('keypress', e => {
                // const key = e.which;
                // switch (key) {
                //     case this.KEY_ENTER:
                //         const position = this.getFragmentPosition(model);
                //         this.create(null, {
                //             position,
                //             focus: true
                //         });
                //         e.preventDefault();
                //         return false;
                // }
            });
        }

        const addItem = (content = '') => {
            if (nbItems < maxItems) {
                nbItems++;
                const $item = $(itemTpl({ content: '' }));
                $el.find('.list-items').append($item);

                const $textarea = $item.find('textarea');
                $textarea.val(content)
                initTextarea($textarea)
            }
            if (nbItems >= maxItems) {
                $el.find('.list-items__add-btn').remove();
            }
        }


        // ADD BUTTON
        $fragment.find('.list-items__add-btn').on('click', () => {
            addItem()
        })

        const content = this.getFragmentContent(model);
        
        console.log('content', {content})
        const $content = $(content);
        const $items = $content.find('li');

        if ($items.length) {
            $items.each((index, item) => {
                const itemContent = $(item).html().trim()
                if (itemContent) {
                    addItem(itemContent)
                }
            })

            if (nbItems === 0) {
                addItem('');
            }
        } else {
            // ADD ONLY TEXT
            addItem(this.getFragmentContent(model, true))
        }
        
        
        

        // $textarea.val(content)



        // //
        // // METHODS
        // //
        // const onFocus = e => {
        //     this.fragments.forEach(fragment => this.removeOptions(fragment));
        //     onChange(e);
        // };

        // const onUpdate = e => {
        //     const content = this.getEditorContent(editor);
        //     const items = $(content).map(function(i,v) {
        //         return $(this).text();
        //     }).toArray();
            
        //     model.set({ content, items });
        // };

        // const onChange = e => {
        //     const content = this.getEditorContent(editor, true);
        //     console.log('onChange')
        //     // if (content.length) {
        //     //     this.removeOptions(model);
        //     // } else {
        //     //     this.addOptions(model);
        //     // }
        // };

        // //
        // // EVENTS
        // //
        // this.mediumEditorEvents(model);

        // editor.subscribe('focus', onFocus);
        // editor.subscribe('editableInput', onChange);
        // editor.subscribe('editableInput', onUpdate);
        // editor.subscribe('editableBlur', onUpdate);

        // //
        // // TRIGGER EVENTS
        // //
        // onChange();

        // if (conf.focus) {
        //     this.fragmentListTrigger('focus', model, {
        //         end: conf.focusEnd
        //     });
        // }

        //
        // TRIGGER EVENTS
        //
        if (conf.focus) {
            this.fragmentListTrigger('focus', model);
        }
    },

    /**
     * Methods to execute in a fragment text type.
     *
     * @param  {String} method
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     * @param  {Boolean} [conf.start]
     * @param  {Boolean} [conf.end]
     */
    fragmentListTrigger: function (method, model, conf = {}) {
        const $el = model.view.$el;
        const $title = $el.find('textarea[name="title"]');
        
        // Focus the main fragment content.
        const focus = () => {
            $title.trigger('focus');

            utils.win.scrollTo($el);
        };

        switch (method) {
            case 'focus': focus(); break;
            default: this.log(`Fragment Text trigger not found "${method}".`);
        }
    }
});