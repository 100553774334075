const stampit = require('stampit');
const config = require('../../config');
const utils = require('../../utils');
const ChapterFragmentsCollection = require('../../collections/ChapterFragments');


/**
 * This is the factory initializator which is in charge of setting up the environment
 * states.
 */
module.exports = stampit().init(function () {
    //
    // ELEMENTS
    //

    // Create the container of new dinamically created elements. For example,
    // the medium editor toolbars.
    // this.$container = $(`<div class="${this.CLASS_CONTAINER}"></div>`);
    // this.$element.append(this.$container);


    //
    // FRAGMENTS
    //

    // NOTE: The chapter content structure is stored in the server as:
    // `{ content: { fragments: [...] } }`.
    // Here, we treat the `content.framents` in `this.fragments`.

    // Create a new fragments collection structure.
    this.fragments   = new ChapterFragmentsCollection();
    this.fullContent = {}; 

    // Create the current fragments in the editor.
    let currentContent;
    try {
        var original = JSON.parse(this.chapterModel.get('content'));
        //fallback
        if(!original){
            original = { 
                en: {fragments: []},
                es: {fragments: []}
            };
        }

        /*Fallback*/
        var obj = {};
        if(!original.en){
            obj.en = original.fragments ? original : {
                fragments: []
            };
        }else{
            obj =  original;
        }

        if(!obj[this.lang]){
            obj[this.lang] =  obj.en;
        }

        this.fullContent = obj;
        currentContent = obj[this.lang];
        if (!currentContent) throw 0;
    } catch (e) {
        currentContent = {
            fragments: []
        };
    }

    const currentFragments = currentContent.fragments;

    // Create all the current fragments.
    _(currentFragments).each(fragment => this.create(fragment, {initial: true}));

    // If there are no fragments, create an empty "fragment text" in the beginning.
    if (!currentFragments.length) {
        this.create(null, {
            placeholder: 'Add chapter content...'
        });
    }

    // If the editor gets completly empty, create an empty one in the beginning.
    this.fragments.on('remove reset', () => {
        if (!this.fragments.length) {
            this.create(null, {
                position: 'first',
                placeholder: 'Add chapter content...',
                focus: true
            });
        }
    });

    const WPM = config.CMS.PROGRAMS.CHAPTERS.READ_WORDS_PER_MINUTE;
    const fragmentProps = ['id', 'chapter', 'type', 'title', 'items', 'options', 'content', 'url', 'desktop_video', 'mobile_video', 'align'];

    // After we create the current fragments, set an event listener for changes
    // in the content and update the model when necessary.
    const update = utils.general.throttle(() => {

        // The content value.
        let content = {
            fragments: this.fragments
                .map(fragment => fragment.toJSON(true))
                .map(fragment => _(fragment).pick(fragmentProps))
                .map(fragment => {
                    for (let p in fragment) {
                        if (fragment[p] === null) delete fragment[p];
                    }
                    return fragment;
                })
        };

        // The read time value.
        // let read_time = content.fragments.
        //     map(fragment => {
        //         let content = fragment.content ? String(fragment.content) : '';
        //         content = content.replace(/\s{1,}/g, ' ').trim();
        //         return content.split(' ').length;
        //     }).
        //     reduce((sum, current) => sum + current);
        //merge
        let tmp = {};
            tmp[this.lang] = content;// new content
        let toSave = _.extend({}, this.fullContent, tmp);

        content = JSON.stringify(toSave);  // Object to String.
        // read_time = Math.ceil(read_time / WPM);  // Reading minutes to milliseconds.

        this.chapterModel.set({ content });
        
        this.log('Update made.');

    }, 1000);

    this.fragments.on('change add remove reset', update);
});
