/**
 * Each fragment has a wrapper view which will contain all the intern interface
 * for the fragment. This view will server as a container for each fragment interface.
 */

const template = _.template(require('../../templates/chapter-fragments-editor/element.html'));


module.exports = Backbone.View.extend({

    template,
    className: 'cfe-fragment',

    initialize: function () {

        const model = this.model;
        const cfe = this.model.cfe;

        this.$el.attr('id', 'cfe-'+ model.get('id'));

        switch (model.get('type')) {
            case 'list':
                this.$el.addClass(cfe.CLASS_LIST);
                break;
            case 'image':
                this.$el.addClass(cfe.CLASS_IMAGE);
                break;
            case 'video':
                this.$el.addClass(cfe.CLASS_VIDEO);
                break;
            case 'question':
                this.$el.addClass(cfe.CLASS_QUESTION);
                break;
            case 'transition':
                this.$el.addClass(cfe.CLASS_TRANSITION);
                break;
            case 'text_image':
                this.$el.addClass(cfe.CLASS_TEXT_IMAGE);
                break;
            default:
                this.$el.addClass(cfe.CLASS_TEXT);
                break;
        }

        return this.render();
    },

    render: function () {
        this.$el.html(template(this.model.toJSON()));
        return this;
    }
});
