const stampit = require('stampit');
const utils = require('../../utils');
const template = _.template(require('../../../templates/chapter-fragments-editor/quote.html'));


module.exports = stampit().methods({

    /**
     * Create new fragment quote with the model and view container created.
     *
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentQuote: function (model, conf = {}) {

        const _this = this;
        const $el = model.view.$el;
        const content = model.get('content') ? $.trim(String(model.get('content'))) : '';

        // Setting content.
        $el.find('.'+ this.CLASS_FRAGMENT_CONTENT).html(template({ content }));

        // Define the editor configuration.
        const nodeEditor = $el.find('.'+ this.CLASS_EDITOR)[0];
        const editorSettings = {
            unquoter: function () {
                const editor = this.base;
                const content = _this.getEditorContent(editor, true);
                _this.replace(model, {
                    type: 'text',
                    content: `<p>${content}</p>`
                });
            }
        };

        // Creating editor.
        const editor = this.mediumEditor(nodeEditor, editorSettings);
        model.view.editor = editor;
        model.view.nodeEditor = nodeEditor;

        //
        // METHODS
        //
        const onUpdate = () => {
            const content = this.getEditorContent(editor, true);
            model.set({ content });
        };

        const onEnterKey = () => {
            const position = this.getFragmentPosition(model);
            this.create(null, {
                position: position,
                focus: true
            });
        };

        //
        // EVENTS
        //
        this.mediumEditorEvents(model);

        editor.subscribe('editableInput', onUpdate);
        editor.subscribe('editableBlur', onUpdate);

        //
        // TRIGGER EVENTS
        //
        if (conf.focus) {
            this.fragmentQuoteTrigger('focus', model, {
                end: conf.focusEnd
            });
        }
    },

    /**
     * Methods to execute in a fragment quote type.
     *
     * @param  {String} method
     * @param  {Backbone.Model} model
     * @param  {Object} [conf]
     */
    fragmentQuoteTrigger: function (method, model, conf = {}) {

        const $el = model.view.$el;
        const editor = model.view.editor;
        const nodeEditor = model.view.nodeEditor;

        // Focus the main fragment content.
        const focus = () => {
            this.focusEditor(editor, conf);
            utils.win.scrollTo($el);
        };

        switch (method) {
            case 'focus': focus(); break;
            default: this.log(`Fragment Quote trigger not found "${method}".`);
        }
    }
});
