const ozToMl = 30;
const lbToKg = 0.5;

// start o space with: (9 ¼ |  ¼ | (1(.5)?/8(.5)?)  | 20(.5)? - 40(.5)? | 34(.5)?  )
const globalRegex = '(\\s|^)((\\d+)\\s?(¼|½|¾)|(¼|½|¾)|(\\d+(\\.\\d{1,4})?)\\s?(\\/(\\s)?(\\d+(\\.\\d{1,4})?))|(\\d+(\\.\\d{1,4})?)\\s?(-(\\s)?(\\d+(\\.\\d{1,4})?))|(\\d+(\\.\\d{1,4})?))';

module.exports = {

    removeTrailingZeros(_value) {

        let value = _value.toString();

        // f not containing a dot, we do not need to do anything
        if (value.indexOf('.') === -1) {
            return value;
        }

        // as long as the last character is a 0 or a dot, remove it
        while ((value.slice(-1) === '0' || value.slice(-1) === '.') && value.indexOf('.') !== -1) {
            value = value.substr(0, value.length - 1);
        }
        return value;

    },

    convertOzToMl(str) {

        let text = str;

        const regex = new RegExp(`${globalRegex}(\\s)?(oz|ounces|ounce|onzas|onza)`, 'ig');

        // Search matches
        const matches = String(text).replace(/<\/?[^>]+(>|$)/g, '').match(regex);
        if (!matches) {
            return text;
        }

        // Check matches
        matches.forEach((_m) => {

            const m = String(_m);

            let metrticLetter = 'oz';
            if (m.toLowerCase().indexOf('ounces') >= 0) {
                metrticLetter = 'ounces';
            } else if (m.toLowerCase().indexOf('ounce') >= 0) {
                metrticLetter = 'ounce';
            } else if (m.toLowerCase().indexOf('onzas') >= 0) {
                metrticLetter = 'onzas';
            } else if (m.toLowerCase().indexOf('onza') >= 0) {
                metrticLetter = 'onza';
            }

            const regexLetter = new RegExp(`(${metrticLetter})`, 'ig');
            let value = String(m.replace(regexLetter, '')).trim();

            if (value.indexOf('¼') >= 0) {
                value = 0.25 + Number(value.replace('¼', '').trim());
            } else if (value.indexOf('½') >= 0) {
                value = 0.5 + Number(value.replace('½', '').trim());
            } else if (value.indexOf('¾') >= 0) {
                value = 0.75 + Number(value.replace('¾', '').trim());
            } else if (/(\d+)*\s?-\s?(\d+)/.test(value)) { // Test 40-50 oz
                value = value.split('-').map(v => Number(v));
            } else if (/(\d+)*\s?\/\s?(\d+)/.test(value)) { // Test 1/8 oz
                /* eslint-disable */
                value = Number(eval(value.replace(/\s/g, '')));
                /* eslint-enable */
            } else {
                value = Number(value);
            }

            const iterator = Array.isArray(value) ? value : [value];
            const newValue = [];

            iterator.forEach((v) => {
                if (value && value !== 'NaN' && !Number.isNaN(v)) {
                    newValue.push(this.removeTrailingZeros(Number(v * ozToMl).toFixed(2)));
                }
            });

            if (newValue.length > 0) {
                const newRegex = new RegExp(`(\\w+)?${m.trim()}`, 'ig');
                text = text.replace(newRegex, `${newValue.join('-')} ml`);
            }

        });

        return String(text).trim();

    },

    convertMlToOz(str) {

        let text = str;

        const regex = new RegExp(`${globalRegex}(\\s)?(ml|milliliters|milliliter|mililitros|mililitro)`, 'ig');

        // Search matches
        const matches = String(text).replace(/<\/?[^>]+(>|$)/g, '').match(regex);
        if (!matches) {
            return text;
        }

        // Check matches
        matches.forEach((m) => {

            let metrticLetter = 'ml';
            if (String(m).toLowerCase().indexOf('milliliters') >= 0) {
                metrticLetter = 'milliliters';
            } else if (String(m).toLowerCase().indexOf('milliliter') >= 0) {
                metrticLetter = 'milliliter';
            } else if (String(m).toLowerCase().indexOf('mililitros') >= 0) {
                metrticLetter = 'mililitros';
            } else if (String(m).toLowerCase().indexOf('mililitro') >= 0) {
                metrticLetter = 'mililitro';
            }

            const regexLetter = new RegExp(`(${metrticLetter})`, 'ig');
            let value = String(m.replace(regexLetter, '')).trim();

            if (/(\d+)*\s?-\s?(\d+)/.test(value)) { // Test 40-50 ml
                value = value.split('-').map(v => Number(v));
            } else if (/(\d+)*\s?\/\s?(\d+)/.test(value)) { // Test 1/8 ml
                /* eslint-disable */
                value = Number(eval(value.replace(/\s/g, '')));
                /* eslint-enable */
            } else {
                value = Number(value);
            }

            const iterator = Array.isArray(value) ? value : [value];
            const newValue = [];
            iterator.forEach((v) => {

                if (value && value !== 'NaN' && !Number.isNaN(v)) {

                    const tmpValue = Number(Number(v) / ozToMl).toFixed(2);

                    let tmpNewValue = String(this.removeTrailingZeros(tmpValue)).replace(/^0+/, '');

                    if (tmpNewValue.indexOf('.25') >= 0) {
                        tmpNewValue = tmpNewValue.replace('.25', '¼');
                    } else if (tmpNewValue.indexOf('.5') >= 0) {
                        tmpNewValue = tmpNewValue.replace('.5', '½');
                    } else if (tmpNewValue.indexOf('.75') >= 0) {
                        tmpNewValue = tmpNewValue.replace('.75', '¾');
                    }

                    newValue.push(tmpNewValue);

                }
            });

            if (newValue.length > 0) {
                const newRegex = new RegExp(`(\\w+)?${m.trim()}`, 'ig');
                text = text.replace(newRegex, `${newValue.join('-')} oz`);
            }

        });

        return String(text).trim();

    },

    convertLbToKg(str) {

        let text = str;

        const regex = new RegExp(`${globalRegex}(\\s)?(lb|pounds|pound|libras|libra)`, 'ig');

        // Search matches
        const matches = String(text).replace(/<\/?[^>]+(>|$)/g, '').match(regex);
        if (!matches) {
            return text;
        }

        // Check matches
        matches.forEach((m) => {

            let metrticLetter = 'lb';
            if (String(m).toLowerCase().indexOf('pounds') >= 0) {
                metrticLetter = 'pounds';
            } else if (String(m).toLowerCase().indexOf('pound') >= 0) {
                metrticLetter = 'pound';
            } else if (String(m).toLowerCase().indexOf('libras') >= 0) {
                metrticLetter = 'libras';
            } else if (String(m).toLowerCase().indexOf('libra') >= 0) {
                metrticLetter = 'libra';
            }

            const regexLetter = new RegExp(`(${metrticLetter})`, 'ig');
            let value = String(m.replace(regexLetter, '')).trim();

            if (/(\d+)*\s?-\s?(\d+)/.test(value)) { // Test 4-5 lb
                value = value.split('-').map(v => Number(v));
            } else if (/(\d+)*\s?\/\s?(\d+)/.test(value)) { // Test 1/4 lb
                /* eslint-disable */
                value = Number(eval(value.replace(/\s/g, '')));
                /* eslint-enable */
            } else {
                value = Number(value);
            }

            const iterator = Array.isArray(value) ? value : [value];
            const newValue = [];
            iterator.forEach((v) => {

                if (value && value !== 'NaN' && !Number.isNaN(v)) {

                    const tmpNewValue = this.removeTrailingZeros(Number(value * lbToKg).toFixed(3));
                    newValue.push(tmpNewValue);

                }

            });

            if (newValue.length > 0) {
                const newRegex = new RegExp(`(\\w+)?${m.trim()}`, 'ig');
                text = text.replace(newRegex, `${newValue.join('-')} kg`);
            }

        });

        return String(text).trim();

    },

    convertKgToLb(str) {

        let text = str;

        const regex = new RegExp(`${globalRegex}(\\s)?(kg|kilos|kilo|kilograms|kilogram|kilogramos|kilogramo)`, 'ig');

        // Search matches
        const matches = String(text).replace(/<\/?[^>]+(>|$)/g, '').match(regex);
        if (!matches) {
            return text;
        }

        // Check matches
        matches.forEach((m) => {

            // important the order of letters!
            let metrticLetter = 'kg';
            if (String(m).toLowerCase().indexOf('kilos') >= 0) {
                metrticLetter = 'kilos';
            } else if (String(m).toLowerCase().indexOf('kilograms') >= 0) {
                metrticLetter = 'kilograms';
            } else if (String(m).toLowerCase().indexOf('kilogramos') >= 0) {
                metrticLetter = 'kilogramos';
            } else if (String(m).toLowerCase().indexOf('kilogramo') >= 0) {
                metrticLetter = 'kilogramo';
            } else if (String(m).toLowerCase().indexOf('kilogram') >= 0) {
                metrticLetter = 'kilogram';
            } else if (String(m).toLowerCase().indexOf('kilo') >= 0) {
                metrticLetter = 'kilo';
            }

            const regexLetter = new RegExp(`(${metrticLetter})`, 'ig');
            let value = String(m.replace(regexLetter, '')).trim();

            if (/(\d+)*\s?-\s?(\d+)/.test(value)) { // Test 4-5 lb
                value = value.split('-').map(v => Number(v));
            } else if (/(\d+)*\s?\/\s?(\d+)/.test(value)) { // Test 1/4 lb
                /* eslint-disable */
                value = Number(eval(value.replace(/\s/g, '')));
                /* eslint-enable */
            } else {
                value = Number(value);
            }

            const iterator = Array.isArray(value) ? value : [value];
            const newValue = [];
            iterator.forEach((v) => {

                if (value && value !== 'NaN' && !Number.isNaN(v)) {

                    const tmpNewValue = this.removeTrailingZeros(Number(value / lbToKg).toFixed(2));
                    newValue.push(tmpNewValue);

                }

            });

            if (newValue.length > 0) {
                const newRegex = new RegExp(`(\\w+)?${m.trim()}`, 'ig');
                text = text.replace(newRegex, `${newValue.join('-')} lb`);
            }

        });

        return String(text).trim();

    },

    convertIntToUs(text) {
        const tmp = this.convertMlToOz(text);
        return this.convertKgToLb(tmp);
    },

    convertUsToInt(text) {
        const tmp = this.convertOzToMl(text);
        return this.convertLbToKg(tmp);
    }

};

// console.log(module.exports.convertOzToMl('has 1oz'));
// console.log(module.exports.convertOzToMl('1 oz'));
// console.log(module.exports.convertOzToMl('1 oz.'));
// console.log(module.exports.convertOzToMl('1oz.'));
// console.log(module.exports.convertOzToMl('1 ounce'));
// console.log(module.exports.convertOzToMl('1 ounces'));
// console.log(module.exports.convertOzToMl('1 onzas'));
// console.log(module.exports.convertOzToMl('1 onza'));
// console.log(module.exports.convertOzToMl('1¼ onza'));
// console.log(module.exports.convertOzToMl('1/4 onza'));
// console.log(module.exports.convertOzToMl('10-20 onza'));
// console.log(module.exports.convertOzToMl('mago de oz'));
// console.log(module.exports.convertOzToMl('answ1 Edited with 2oz'));
// console.log(module.exports.convertOzToMl('<p>4oz</p> esto 2oz 2.5oz mago de oz oz'));
// console.log(module.exports.convertOzToMl('1 oz 1 ¼oz'));
// console.log(module.exports.convertOzToMl('1¼ oz 2¼ oz ¼oz'));
// console.log(module.exports.convertOzToMl('3-6 ounces and 4 onzas 1 ounce or 1/4 oz'));

// console.log(module.exports.convertMlToOz('answ1 Edited with 60ml'));
// console.log(module.exports.convertMlToOz('<p>15ml</p>'));
// console.log(module.exports.convertMlToOz('esto 60ml y 75ml mago de ml'));
// console.log(module.exports.convertMlToOz('30 ml'));
// console.log(module.exports.convertMlToOz('37.5ml'));
// console.log(module.exports.convertMlToOz('37.5ml 67.5ml'));
// console.log(module.exports.convertMlToOz('7.5ml'));
// console.log(module.exports.convertMlToOz('tiene de 6/8 ml'));
// console.log(module.exports.convertMlToOz('tien más 30ml'));
// console.log(module.exports.convertMlToOz('30ml'));
// console.log(module.exports.convertMlToOz('30 ml.'));
// console.log(module.exports.convertMlToOz('30ml.'));
// console.log(module.exports.convertMlToOz('30 milliliter'));
// console.log(module.exports.convertMlToOz('60 milliliters'));
// console.log(module.exports.convertMlToOz('30 mililitros'));
// console.log(module.exports.convertMlToOz('30mililitro'));
// console.log(module.exports.convertMlToOz('37.5 ml'));
// console.log(module.exports.convertMlToOz('30-60 ml'));
// console.log(module.exports.convertMlToOz('30/2 ml'));
// console.log(module.exports.convertMlToOz('30-60 milliliter'));
// console.log(module.exports.convertMlToOz('mago de ml'));

//
//
//
// console.log(module.exports.convertLbToKg('answ1 Edited with 1lb'));
// console.log(module.exports.convertLbToKg('<p>1lb</p>'));
// console.log(module.exports.convertLbToKg('esto 2lb 4lb mago de lb'));
// console.log(module.exports.convertLbToKg('6 lb'));
// console.log(module.exports.convertLbToKg('8.5 lb'));
// console.log(module.exports.convertLbToKg('6.5lb 7.5lb'));
// console.log(module.exports.convertLbToKg('5lb'));
//
// console.log(module.exports.convertKgToLb('answ1 Edited with 0.5 kg'));
// console.log(module.exports.convertKgToLb('<p>0.5kg</p>'));
// console.log(module.exports.convertKgToLb('esto 1kg 2kg mago de kg'));
// console.log(module.exports.convertKgToLb('3 kg'));
// console.log(module.exports.convertKgToLb('4.25 kg'));
// console.log(module.exports.convertKgToLb('4.5kg 3.75kg'));
// console.log(module.exports.convertKgToLb('2.5kg'));

/**
 * TESTING
 */
/*
// start o space with: (9 ¼ |  ¼ | (1(.5)?/8(.5)?)  | 20(.5)? - 40(.5)? | 34(.5)?  )
const regex = /(\s|^)((\d+)\s?(¼|½|¾)|(¼|½|¾)|(\d+(\.\d{1,4})?)\s?(\/(\s)?(\d+(\.\d{1,4})?))|(\d+(\.\d{1,4})?)\s?(-(\s)?(\d+(\.\d{1,4})?))|(\d+(\.\d{1,4})?))/ig;
const data = [
  '8¼',
  '8½',
  '8 ¾',
  '¾', '8¾',
  '8¾',
  8,
  '¾',
  'hello 7',
  '8.6',
  '8.9.9',
  '7-10',
  '7.5-22.5',
  'abc-4',
  '5-error',
  '1/8',
  '5.5/7.5',
  'error/8.8',
  '7/error',
  'this is a test'
];

data.forEach( (v) => {
  const matches = String(v).match(regex);
  (matches || []).forEach( (a) => {
    console.log(a);
  });
});
 *
 */