const stampit = require('stampit');


module.exports = stampit().methods({

    /**
     * Replace a fragment for another. This will delete the current one and
     * create a brand new extending the former data with the new one.
     *
     * @param  {Backbone.Model} model - Fragment model to replace.
     * @param  {Object} [conf]
     *
     * @return {Backbone.Model} - Returns the new created fragment model.
     */
    replace: function (model, conf={}) {

        const fragments = this.fragments;
        const position = this.getFragmentPosition(model);
        const positionToInsert = position === 0 ? 'first' : position - 1;

        this.remove(model);

        // Return the new model created.
        return this.create({
            type: conf.type || model.get('type') || 'text',
            content: conf.content || model.get('content') || ''
        }, {
            position: positionToInsert,
            focus: true,
            focusEnd: true
        });
    }
});
